import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { userInfoState } from "../../atoms";
import FanddleLogoForLogin from "../fanddleLogoForLogin.components";
import { EmailInput, FormData, PwInput } from "../loginModal.components";
import PwProgressBar from "./pwProgressBar.components";

export interface passwordType {
  name: string;
  type: string;
}
interface setPassSignUpProps {
  setPassSignUp1: React.Dispatch<React.SetStateAction<boolean>>;
}

function SignUp1({ setPassSignUp1 }: setPassSignUpProps) {
  const setUserInfo = useSetRecoilState(userInfoState);
  const [passwordType, setPasswordType] = useState<passwordType[]>([
    {
      name: "password",
      type: "password",
    },
    {
      name: "password2",
      type: "password",
    },
  ]);
  const [isPwValid, setIsPwValid] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { dirtyFields },
  } = useForm<FormData>({
    defaultValues: {
      email: "",
      password1: "",
      password2: "",
      keepLoggedIn: false,
    },
  });
  const inputArr = Object.keys(dirtyFields);
  const isAllFilled = inputArr.length === 3;
  const isEmailFilled = inputArr.includes("email");
  const isPwFilled = inputArr.includes("password1");
  const isPw2Filled = inputArr.includes("password2");
  const isPwEqual = watch("password1") === watch("password2");

  function cleanInput() {
    setValue("email", "", { shouldDirty: true });
  }

  function togglePw1Visible(type: string) {
    setPasswordType((prev) => {
      const target: any = prev.find((el) => el.name === type);
      const rest = prev.find((el) => el.name !== type);
      if (target.type === "password") {
        return [rest, { ...target, type: "text" }];
      } else {
        return [rest, { ...target, type: "password" }];
      }
    });
  }

  const onSubmit = handleSubmit((data) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/signin/guest`, {
        lang: "ko",
      })
      .then((res) => res.data.data.guestToken)
      .then((res) =>
        axios.post(
          `${process.env.REACT_APP_BASE_URL}/send/email/auth`,
          {
            email: data.email,
            password: data.password2,
            isWeb: true,
          },
          {
            headers: {
              Authorization: `Bearer ${res}`,
            },
          },
        ),
      )
      .then((res) => {
        setUserInfo((prev) => {
          return {
            ...prev,
            email: data.email,
            id: data.email,
            password: data.password1,
            emailToken: res.data.data.token,
          };
        });
        setPassSignUp1(true);
      });
  });

  return (
    <Wrapper>
      <FanddleLogoForLogin />
      <GuideMsg>
        사용하실 이메일과 비밀번호를 <br />
        입력해 주세요.
      </GuideMsg>
      <SignupForm onSubmit={onSubmit}>
        <label htmlFor="email">이메일</label>
        <EmailInput1
          filled={isEmailFilled}
          id="email"
          placeholder="이메일을 입력해주세요."
          type="text"
          {...register("email")}
        />
        <ResetBtn
          onClick={cleanInput}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          active={Object.keys(dirtyFields).includes("email")}>
          <rect
            x="20.2246"
            y="4.95117"
            width="21.5982"
            height="1.5"
            rx="0.75"
            transform="rotate(135 20.2246 4.95117)"
            fill="#AAAAAA"
          />
          <rect
            x="4.95264"
            y="3.89062"
            width="21.5982"
            height="1.5"
            rx="0.75"
            transform="rotate(45 4.95264 3.89062)"
            fill="#AAAAAA"
          />
        </ResetBtn>
        <label htmlFor="password1" className="no-drag">
          비밀번호
        </label>
        <PwInput1
          filled={isPwFilled}
          id="password1"
          placeholder="비밀번호를 입력해주세요."
          type={passwordType.find((el) => el.name === "password")?.type}
          {...register("password1")}
        />
        {passwordType.find((el) => el.name === "password")?.type === "password" ? (
          <InvisibleBtn
            onClick={() => {
              togglePw1Visible("password");
            }}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            active={Object.keys(dirtyFields).includes("password1")}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.19364 17.7539C1.99838 17.5587 1.99838 17.2421 2.19364 17.0468L17.0429 2.19759C17.2381 2.00233 17.5547 2.00233 17.75 2.19759C17.9452 2.39285 17.9452 2.70944 17.75 2.9047L2.90075 17.7539C2.70549 17.9492 2.3889 17.9492 2.19364 17.7539Z"
              fill="#AAAAAA"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.235 7.26519C10.8584 7.09483 10.4403 7 10 7C8.34315 7 7 8.34315 7 10C7 10.4403 7.09483 10.8584 7.26519 11.235L8.05071 10.4495C8.01753 10.305 8 10.1545 8 10C8 8.89543 8.89543 8 10 8C10.1545 8 10.305 8.01753 10.4495 8.05071L11.235 7.26519ZM9.4154 11.9132C9.60032 11.9696 9.79662 12 10 12C11.1046 12 12 11.1046 12 10C12 9.79662 11.9696 9.60032 11.9132 9.4154L12.6794 8.6492C12.8845 9.05518 13 9.5141 13 10C13 11.6569 11.6569 13 10 13C9.5141 13 9.05518 12.8845 8.6492 12.6794L9.4154 11.9132Z"
              fill="#AAAAAA"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.0607 5.57501C13.0535 5.57225 13.0464 5.5695 13.0393 5.56675C12.1429 5.22201 11.1659 5 10.1354 5C7.73731 5 5.62881 6.20247 4.15126 7.35587C3.40677 7.93703 2.80762 8.51731 2.39422 8.95252C2.18725 9.17042 2.0261 9.35273 1.91584 9.48166C1.86068 9.54614 1.81821 9.59734 1.78904 9.63302C1.77445 9.65087 1.76319 9.66484 1.75532 9.67467L1.74609 9.68626L1.7434 9.68967L1.74254 9.69076L1.5 10L1.742 10.3086L1.7434 10.3103L1.74609 10.3137L1.75532 10.3253C1.76319 10.3352 1.77445 10.3491 1.78904 10.367C1.81821 10.4027 1.86068 10.4539 1.91584 10.5183C2.0261 10.6473 2.18725 10.8296 2.39422 11.0475C2.80762 11.4827 3.40677 12.063 4.15126 12.6441C4.48013 12.9009 4.84026 13.16 5.22789 13.4078L5.95337 12.6823C5.52856 12.4218 5.13118 12.1405 4.7666 11.8559C4.06992 11.312 3.50732 10.7673 3.11927 10.3588C2.98912 10.2218 2.87894 10.1004 2.79025 10C2.87894 9.89958 2.98912 9.77824 3.11927 9.64123C3.50732 9.23269 4.06992 8.68797 4.7666 8.14413C6.17138 7.04753 8.06288 6 10.1354 6C10.8777 6 11.5967 6.13436 12.2785 6.3572L13.0607 5.57501Z"
              fill="#AAAAAA"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.93933 14.425C6.94645 14.4277 6.95358 14.4305 6.96072 14.4332C7.85714 14.778 8.83411 15 9.86456 15C12.2627 15 14.3712 13.7975 15.8487 12.6441C16.5932 12.063 17.1924 11.4827 17.6058 11.0475C17.8128 10.8296 17.9739 10.6473 18.0842 10.5183C18.1393 10.4539 18.1818 10.4027 18.211 10.367C18.2255 10.3491 18.2368 10.3352 18.2447 10.3253L18.2539 10.3137L18.2566 10.3103L18.2575 10.3092L18.5 10L18.258 9.69144L18.2566 9.68967L18.2539 9.68626L18.2447 9.67467C18.2368 9.66484 18.2255 9.65087 18.211 9.63302C18.1818 9.59734 18.1393 9.54614 18.0842 9.48166C17.9739 9.35273 17.8128 9.17042 17.6058 8.95252C17.1924 8.51731 16.5932 7.93703 15.8487 7.35587C15.5199 7.09914 15.1597 6.83999 14.7721 6.5922L14.0466 7.31769C14.4714 7.57823 14.8688 7.85953 15.2334 8.14413C15.9301 8.68797 16.4927 9.23269 16.8807 9.64123C17.0109 9.77824 17.1211 9.89958 17.2097 10C17.1211 10.1004 17.0109 10.2218 16.8807 10.3588C16.4927 10.7673 15.9301 11.312 15.2334 11.8559C13.8286 12.9525 11.9371 14 9.86456 14C9.12231 14 8.40329 13.8656 7.72152 13.6428L6.93933 14.425Z"
              fill="#AAAAAA"
            />
          </InvisibleBtn>
        ) : (
          <VisibleBtn
            active={Object.keys(dirtyFields).includes("password1")}
            onClick={() => {
              togglePw1Visible("password");
            }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10 5.5C5.52931 5.5 2 10 2 10C2 10 5.52931 14.5 10 14.5C14.4707 14.5 18 10 18 10C18 10 14.4707 5.5 10 5.5Z"
              stroke="#333333"
            />
            <circle cx="10" cy="10" r="2.5" stroke="#333333" />
          </VisibleBtn>
        )}
        <PwGuideMsg>영문 대문자, 숫자, 특수문자[!@#$%^&*?]포함 최소 6자 이상</PwGuideMsg>
        {Object.keys(dirtyFields).includes("password1") && (
          <PwProgressBar pw={watch("password1")} setIsPwValid={setIsPwValid} />
        )}
        <Pw2Label htmlFor="password2" className="no-drag">
          비밀번호 확인
        </Pw2Label>
        <Pw2Wrapper>
          <PwInput2
            filled={isPw2Filled}
            id="password2"
            isPwValid={isPwEqual}
            placeholder="한번 더 입력해주세요."
            type={passwordType.find((el) => el.name === "password2")?.type}
            {...register("password2")}
          />
          {isPwFilled ? (
            !isPwEqual ? (
              <PwWarnMsg>비밀번호가 일치하지 않습니다.</PwWarnMsg>
            ) : (
              <PwPassMsg>비밀번호가 일치합니다.</PwPassMsg>
            )
          ) : null}
          {passwordType.find((el) => el.name === "password2")?.type === "password" ? (
            <InvisibleBtn2
              active={Object.keys(dirtyFields).includes("password2")}
              onClick={() => {
                togglePw1Visible("password2");
              }}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.19364 17.7539C1.99838 17.5587 1.99838 17.2421 2.19364 17.0468L17.0429 2.19759C17.2381 2.00233 17.5547 2.00233 17.75 2.19759C17.9452 2.39285 17.9452 2.70944 17.75 2.9047L2.90075 17.7539C2.70549 17.9492 2.3889 17.9492 2.19364 17.7539Z"
                fill="#AAAAAA"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.235 7.26519C10.8584 7.09483 10.4403 7 10 7C8.34315 7 7 8.34315 7 10C7 10.4403 7.09483 10.8584 7.26519 11.235L8.05071 10.4495C8.01753 10.305 8 10.1545 8 10C8 8.89543 8.89543 8 10 8C10.1545 8 10.305 8.01753 10.4495 8.05071L11.235 7.26519ZM9.4154 11.9132C9.60032 11.9696 9.79662 12 10 12C11.1046 12 12 11.1046 12 10C12 9.79662 11.9696 9.60032 11.9132 9.4154L12.6794 8.6492C12.8845 9.05518 13 9.5141 13 10C13 11.6569 11.6569 13 10 13C9.5141 13 9.05518 12.8845 8.6492 12.6794L9.4154 11.9132Z"
                fill="#AAAAAA"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.0607 5.57501C13.0535 5.57225 13.0464 5.5695 13.0393 5.56675C12.1429 5.22201 11.1659 5 10.1354 5C7.73731 5 5.62881 6.20247 4.15126 7.35587C3.40677 7.93703 2.80762 8.51731 2.39422 8.95252C2.18725 9.17042 2.0261 9.35273 1.91584 9.48166C1.86068 9.54614 1.81821 9.59734 1.78904 9.63302C1.77445 9.65087 1.76319 9.66484 1.75532 9.67467L1.74609 9.68626L1.7434 9.68967L1.74254 9.69076L1.5 10L1.742 10.3086L1.7434 10.3103L1.74609 10.3137L1.75532 10.3253C1.76319 10.3352 1.77445 10.3491 1.78904 10.367C1.81821 10.4027 1.86068 10.4539 1.91584 10.5183C2.0261 10.6473 2.18725 10.8296 2.39422 11.0475C2.80762 11.4827 3.40677 12.063 4.15126 12.6441C4.48013 12.9009 4.84026 13.16 5.22789 13.4078L5.95337 12.6823C5.52856 12.4218 5.13118 12.1405 4.7666 11.8559C4.06992 11.312 3.50732 10.7673 3.11927 10.3588C2.98912 10.2218 2.87894 10.1004 2.79025 10C2.87894 9.89958 2.98912 9.77824 3.11927 9.64123C3.50732 9.23269 4.06992 8.68797 4.7666 8.14413C6.17138 7.04753 8.06288 6 10.1354 6C10.8777 6 11.5967 6.13436 12.2785 6.3572L13.0607 5.57501Z"
                fill="#AAAAAA"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.93933 14.425C6.94645 14.4277 6.95358 14.4305 6.96072 14.4332C7.85714 14.778 8.83411 15 9.86456 15C12.2627 15 14.3712 13.7975 15.8487 12.6441C16.5932 12.063 17.1924 11.4827 17.6058 11.0475C17.8128 10.8296 17.9739 10.6473 18.0842 10.5183C18.1393 10.4539 18.1818 10.4027 18.211 10.367C18.2255 10.3491 18.2368 10.3352 18.2447 10.3253L18.2539 10.3137L18.2566 10.3103L18.2575 10.3092L18.5 10L18.258 9.69144L18.2566 9.68967L18.2539 9.68626L18.2447 9.67467C18.2368 9.66484 18.2255 9.65087 18.211 9.63302C18.1818 9.59734 18.1393 9.54614 18.0842 9.48166C17.9739 9.35273 17.8128 9.17042 17.6058 8.95252C17.1924 8.51731 16.5932 7.93703 15.8487 7.35587C15.5199 7.09914 15.1597 6.83999 14.7721 6.5922L14.0466 7.31769C14.4714 7.57823 14.8688 7.85953 15.2334 8.14413C15.9301 8.68797 16.4927 9.23269 16.8807 9.64123C17.0109 9.77824 17.1211 9.89958 17.2097 10C17.1211 10.1004 17.0109 10.2218 16.8807 10.3588C16.4927 10.7673 15.9301 11.312 15.2334 11.8559C13.8286 12.9525 11.9371 14 9.86456 14C9.12231 14 8.40329 13.8656 7.72152 13.6428L6.93933 14.425Z"
                fill="#AAAAAA"
              />
            </InvisibleBtn2>
          ) : (
            <VisibleBtn2
              active={Object.keys(dirtyFields).includes("password2")}
              onClick={() => {
                togglePw1Visible("password2");
              }}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 5.5C5.52931 5.5 2 10 2 10C2 10 5.52931 14.5 10 14.5C14.4707 14.5 18 10 18 10C18 10 14.4707 5.5 10 5.5Z"
                stroke="#333333"
              />
              <circle cx="10" cy="10" r="2.5" stroke="#333333" />
            </VisibleBtn2>
          )}
        </Pw2Wrapper>
        <NextBtn active={isPwValid && isAllFilled && isPwEqual} disabled={!isPwValid && !isAllFilled && !isPwEqual}>
          다음
        </NextBtn>
      </SignupForm>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 470px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 16px;
  padding: 50px 40px 40px 40px;
  & > svg {
    display: block;
    margin: 0 auto;
    margin-bottom: 69.52px;
  }
`;
const GuideMsg = styled.p`
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  margin-bottom: 38px;
`;
const SignupForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #333333;
    margin-bottom: 16px;
  }
`;
const EmailInput1 = styled(EmailInput)<{ filled: boolean }>`
  border-color: ${(props) => (props.filled ? "#333333" : "#AAAAAA")};
`;
const PwInput1 = styled(PwInput)<{ filled: boolean }>`
  margin-bottom: 10px;
  border-color: ${(props) => (props.filled ? "#333333" : "#AAAAAA")};
`;
const PwInput2 = styled(PwInput1)<{ isPwValid: boolean; filled: boolean }>`
  width: 100%;
  border-bottom: ${(props) =>
    props.filled && props.isPwValid
      ? "1px solid #333333"
      : !props.isPwValid
      ? "1px solid #FF2323"
      : "1px solid #aaaaaa"};
`;
const PwGuideMsg = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #aaaaaa;
`;
const NextBtn = styled.button<{ active: boolean }>`
  background: ${(props) => (props.active ? "#FF696B" : "#F5F5F5")};
  margin-top: 80px;
  height: 40px;
  border-radius: 4px;
  border: none;
  color: ${(props) => (props.active ? "#FFFFFF" : "#cecece")};
  transition: all 0.3s ease-in-out;
`;
const ResetBtn = styled.svg<{ active: boolean }>`
  position: absolute;
  right: 4px;
  width: 20px;
  height: 20px;
  top: 38px;
  cursor: pointer;
  color: tomato;
  rect {
    fill: ${(props) => (props.active ? "#333333" : "#AAAAAA")};
    transition: fill 0.3s ease-in-out;
  }
`;
const InvisibleBtn = styled.svg<{ active: boolean }>`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 4px;
  top: 150px;
  cursor: pointer;
  path {
    fill: ${(props) => (props.active ? "#333333" : "#AAAAAA")};
    transition: fill 0.3s ease-in-out;
  }
`;
const VisibleBtn = styled.svg<{ active: boolean }>`
  position: absolute;
  right: 4px;
  top: 150px;
  cursor: pointer;
  path {
    stroke: ${(props) => (props.active ? "#333333" : "#AAAAAA")};
  }
  circle {
    stroke: ${(props) => (props.active ? "#333333" : "#AAAAAA")};
  }
  transition: all 0.3s ease-in-out;
`;

const Pw2Wrapper = styled.div`
  position: relative;
`;
const VisibleBtn2 = styled.svg<{ active: boolean }>`
  position: absolute;
  top: 10px;
  right: 4px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  path {
    stroke: ${(props) => (props.active ? "#333333" : "#AAAAAA")};
  }
  circle {
    stroke: ${(props) => (props.active ? "#333333" : "#AAAAAA")};
  }
`;
const InvisibleBtn2 = styled.svg<{ active: boolean }>`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 4px;
  top: 10px;
  cursor: pointer;
  path {
    fill: ${(props) => (props.active ? "#333333" : "#AAAAAA")};
    transition: fill 0.3s ease-in-out;
  }
`;
const PwWarnMsg = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #ff2323;
`;
const PwPassMsg = styled(PwWarnMsg)`
  color: #aaaaaa;
`;
const Pw2Label = styled.label`
  margin-top: 40px;
`;
export default SignUp1;
