import axios from "axios";

export async function getCelebList(pageParam = 0, word = "") {
  const temp = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/love/list/job?page=${pageParam}&count=18${word ? `&word=${word}` : "&word"}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TEST_TOKEN}`,
      },
    },
  );
  const isLastPage = Math.ceil(temp.data.data.total / 18) < pageParam;
  return { res: temp.data.data.loves, isLastPage, nextPage: pageParam + 1 };
}
