import { UseFormSetValue } from "react-hook-form";
import styled from "styled-components";
import { IUserForm } from "./signupComponents/signUp4.components";
type Props = {
  top: number;
  setValue: UseFormSetValue<IUserForm>;
  target: "name" | "nickname" | "recommender";
};
function ResetBtn({ top, setValue, target }: Props) {
  function reset() {
    setValue(target, "", { shouldDirty: true });
  }
  return (
    <ResetSvg onClick={reset} top={top} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="20.2246"
        y="4.95117"
        width="21.5982"
        height="1.5"
        rx="0.75"
        transform="rotate(135 20.2246 4.95117)"
        fill="#AAAAAA"
      />
      <rect
        x="4.95264"
        y="3.89062"
        width="21.5982"
        height="1.5"
        rx="0.75"
        transform="rotate(45 4.95264 3.89062)"
        fill="#AAAAAA"
      />
    </ResetSvg>
  );
}
const ResetSvg = styled.svg<{ top: number }>`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: ${(props) => props.top + "px"};
`;
export default ResetBtn;
