import styled from "styled-components";
import { useForm } from "react-hook-form";
import apple from "../images/logos/appleLogo.png";
import fb from "../images/logos/fbLogo.png";
import { useSetRecoilState } from "recoil";
import { loginState } from "../atoms";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export interface FormData {
  email: string;
  password1: string;
  password2?: string;
  keepLoggedIn?: boolean;
}

function LoginModal() {
  const setLoginModalOn = useSetRecoilState(loginState);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const onSubmit = handleSubmit((data) => {
    console.log(data);
  });
  const navigate = useNavigate();
  const errorMessage = errors?.email?.message || errors?.password1?.message;
  useEffect(() => {
    document.body.style.overflow = `hidden`;
    return () => {
      document.body.style.overflow = `auto`;
    };
  }, []);
  return (
    <>
      <Wrapper>
        <FanddleLogo width="107" height="22" viewBox="0 0 107 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.99882 21.271H6.59324V10.1575H9.0651V6.36398H6.59324V6.2304C6.59324 4.65421 7.21121 4.11991 8.98449 4.22677V0.353076C4.41694 0.11264 1.99882 2.03613 1.99882 5.93653V6.36398H0.333008V10.1575H1.99882V21.271Z"
            fill="#FF696B"
          />
          <path
            d="M10.3105 13.7908C10.3105 18.4926 13.2391 21.4847 16.8931 21.4847C19.1232 21.4847 20.7084 20.4695 21.5413 19.1605V21.271H26.1357V6.36398H21.5413V8.47447C20.7352 7.16543 19.15 6.15025 16.92 6.15025C13.2391 6.15025 10.3105 9.08891 10.3105 13.7908ZM21.5413 13.8175C21.5413 16.1951 20.0098 17.5042 18.2634 17.5042C16.5438 17.5042 14.9855 16.1684 14.9855 13.7908C14.9855 11.4131 16.5438 10.1308 18.2634 10.1308C20.0098 10.1308 21.5413 11.4398 21.5413 13.8175Z"
            fill="#FF696B"
          />
          <path
            d="M39.8037 21.271H44.3713V12.5619C44.3713 8.55461 42.0606 6.20369 38.5947 6.20369C36.5796 6.20369 34.9675 7.08528 34.054 8.34089V6.36398H29.4596V21.271H34.054V13.1763C34.054 11.146 35.1824 10.0239 36.9289 10.0239C38.6753 10.0239 39.8037 11.146 39.8037 13.1763V21.271Z"
            fill="#FF696B"
          />
          <path
            d="M46.6504 13.7908C46.6504 18.4926 49.5791 21.4847 53.26 21.4847C55.4631 21.4847 57.0483 20.4963 57.8812 19.1338V21.271H62.4757V1.50183H57.8812V8.42104C56.9677 7.05857 55.2482 6.15025 53.26 6.15025C49.5791 6.15025 46.6504 9.08891 46.6504 13.7908ZM57.8812 13.8175C57.8812 16.1951 56.3498 17.5042 54.6034 17.5042C52.8838 17.5042 51.3255 16.1684 51.3255 13.7908C51.3255 11.4131 52.8838 10.1308 54.6034 10.1308C56.3498 10.1308 57.8812 11.4398 57.8812 13.8175Z"
            fill="#FF696B"
          />
          <path
            d="M64.886 13.7908C64.886 18.4926 67.8146 21.4847 71.4956 21.4847C73.6987 21.4847 75.2839 20.4963 76.1168 19.1338V21.271H80.7113V1.50183H76.1168V8.42104C75.2033 7.05857 73.4838 6.15025 71.4956 6.15025C67.8146 6.15025 64.886 9.08891 64.886 13.7908ZM76.1168 13.8175C76.1168 16.1951 74.5854 17.5042 72.8389 17.5042C71.1194 17.5042 69.5611 16.1684 69.5611 13.7908C69.5611 11.4131 71.1194 10.1308 72.8389 10.1308C74.5854 10.1308 76.1168 11.4398 76.1168 13.8175Z"
            fill="#FF696B"
          />
          <path d="M84.0351 21.271H88.6295V1.50183H84.0351V21.271Z" fill="#FF696B" />
          <path
            d="M98.5686 9.81022C100.1 9.81022 101.39 10.7453 101.39 12.3749H95.64C95.9087 10.7185 97.064 9.81022 98.5686 9.81022ZM105.742 16.3287H100.852C100.476 17.1302 99.7508 17.7713 98.4611 17.7713C96.9834 17.7713 95.7743 16.863 95.6131 14.9128H106.011C106.065 14.4587 106.092 14.0045 106.092 13.5771C106.092 9.03548 103.002 6.15025 98.6223 6.15025C94.1622 6.15025 91.0456 9.08891 91.0456 13.8175C91.0456 18.5461 94.216 21.4847 98.6223 21.4847C102.357 21.4847 105.017 19.2407 105.742 16.3287Z"
            fill="#FF696B"
          />
        </FanddleLogo>
        <CloseBtn
          onClick={() => {
            setLoginModalOn(false);
          }}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect
            x="20.2246"
            y="4.95117"
            width="21.5982"
            height="1.5"
            rx="0.75"
            transform="rotate(135 20.2246 4.95117)"
            fill="#333333"
          />
          <rect
            x="4.95264"
            y="3.89062"
            width="21.5982"
            height="1.5"
            rx="0.75"
            transform="rotate(45 4.95264 3.89062)"
            fill="#333333"
          />
        </CloseBtn>
        <h1>로그인</h1>
        {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
        <LoginForm onSubmit={onSubmit}>
          <label htmlFor="email" className="no-drag">
            이메일
          </label>
          <EmailInput
            id="email"
            placeholder="이메일을 입력해주세요."
            type="text"
            {...register("email", {
              required: "아이디 혹은 비밀번호가 잘못 입력되었습니다.",
            })}
          />
          <label htmlFor="password" className="no-drag">
            비밀번호
          </label>
          <PwInput
            id="password"
            placeholder="비밀번호를 입력해주세요."
            type="password"
            {...register("password1", { required: "아이디 혹은 비밀번호가 잘못 입력되었습니다." })}
          />
          <CheckBoxWrapper>
            <input type="checkbox" id="keepLoggedIn" {...register("keepLoggedIn")} />
            <label htmlFor="keepLoggedIn" />
            <label className="no-drag" htmlFor="keepLoggedIn">
              로그인 유지하기
            </label>
          </CheckBoxWrapper>
          <LoginBtn>로그인</LoginBtn>
          <KakaoBtn type="button">
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.9987 0.5C4.39676 0.5 0.666504 3.44961 0.666504 7.08883C0.666504 9.4558 2.246 11.5315 4.6198 12.6945C4.44455 13.3454 3.98937 15.0523 3.89833 15.4165C3.78453 15.8694 4.06447 15.8648 4.24655 15.7419C4.38993 15.6463 6.52931 14.192 7.45334 13.5639C7.95404 13.639 8.47068 13.6777 9.00097 13.6777C13.6029 13.6777 17.3332 10.728 17.3332 7.09111C17.3332 3.45416 13.6006 0.5 8.9987 0.5Z"
                fill="#381E1F"
              />
            </svg>
            카카오로 3초만에 시작하기
          </KakaoBtn>
          <AppleBtn type="button">
            <img src={apple} alt="apple logo" />
            Apple로 계속하기
          </AppleBtn>
          <FbBtn type="button">
            <img src={fb} alt="facebook logo" />
            Facebook으로 계속하기
          </FbBtn>
        </LoginForm>
        <footer>
          <span
            onClick={() => {
              navigate("/findPw");
              setLoginModalOn(false);
            }}>
            비밀번호 찾기
          </span>
          <span
            onClick={() => {
              navigate("/signup");
              setLoginModalOn(false);
            }}>
            회원가입
          </span>
        </footer>
      </Wrapper>
      <DeemedBackground
        onClick={() => {
          setLoginModalOn(false);
        }}
      />
    </>
  );
}
export default LoginModal;

const Wrapper = styled.div`
  position: fixed;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 470px;
  background: #ffffff;
  padding: 50px 40px 40px 40px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #333333;
    margin-bottom: 32px;
  }
  footer {
    display: flex;
    justify-content: center;
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      text-align: right;
      color: #aaaaaa;
      padding: 0px 20px;
      cursor: pointer;
      &:first-child {
        border-right: 1px solid #aaaaaa;
      }
    }
  }
`;
const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  label {
    font-size: 14px;
    line-height: 14px;
    color: #333333;
    margin-bottom: 16px;
    max-width: 70px;
  }
  button {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] + label {
    &::after {
      content: "✔";
      color: #aaaaaa;
      font-size: 15px;
      width: 15px;
      height: 15px;
      text-align: center;
      position: absolute;
      left: 3px;
      top: 4px;
      right: 0;
      cursor: pointer;
    }
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    background: #ececec;
  }
  input[id="keepLoggedIn"]:checked + label::after {
    content: "✔";
    color: #ff696b;
    font-size: 15px;
    width: 15px;
    height: 15px;
    text-align: center;
    position: absolute;
    left: 3px;
    top: 4px;
    right: 0;
  }
`;
export const EmailInput = styled.input`
  &::placeholder {
    color: #aaaaaa;
  }
  &::-ms-input-placeholder {
    color: #aaaaaa;
  }
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 5px;
  border: none;
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 40px;
`;
export const PwInput = styled(EmailInput)`
  margin-bottom: 23px;
`;
const CheckBoxWrapper = styled.div`
  label {
    &:last-child {
      font-size: 14px;
      line-height: 14px;
      color: #888888;
      min-width: 100px;
      margin-left: 9px;
    }
  }
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const LoginBtn = styled.button`
  padding: 12px;
  border: none;
  background: #ff696b;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  height: 40px;
`;
const KakaoBtn = styled(LoginBtn)`
  background: #fee500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 11.67px;
  }
`;
const AppleBtn = styled(KakaoBtn)`
  color: #ffffff;
  background: #333333;
  backdrop-filter: blur(20px);
  img {
    width: 15px;
    height: 20px;
    margin-right: 13px;
  }
`;
const FbBtn = styled(AppleBtn)`
  background: #315286;
  img {
    margin-right: 10px;
  }
`;
export const FanddleLogo = styled.svg`
  display: block;
  margin: 0 auto;
  margin-bottom: 69.52px;
`;
const CloseBtn = styled.svg`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`;
const ErrorMsg = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #ff6363;
  margin-bottom: 20px;
`;
const DeemedBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
`;
