import styled from "styled-components";
import { useNavigate } from "react-router-dom";
interface IEmptyStatus {
  EmptyText: string;
  BtnTitle: string;
  arrow: string;
  linkTo: string;
  boxHeight: string;
}

function EmptyStatus({ EmptyText, BtnTitle, arrow, boxHeight, linkTo }: IEmptyStatus) {
  const navigate = useNavigate();
  return (
    <EmptyWrapper boxHeight={boxHeight}>
      <EmpnyText>{EmptyText}</EmpnyText>
      <SuggestBtn
        onClick={() => {
          navigate(linkTo);
        }}>
        {BtnTitle}
        <img src={arrow} alt="arrow" />
      </SuggestBtn>
    </EmptyWrapper>
  );
}
export default EmptyStatus;

/* EmptyStatus Box */
const EmptyWrapper = styled.div<{ boxHeight: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.boxHeight};
`;
const EmpnyText = styled.span`
  display: inline-block;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #aaaaaa;
  margin-bottom: 20px;
`;
const SuggestBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 0 16px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #888888;
  img {
    width: 7.5px;
    height: 11.5px;
    margin-left: 7px;
  }
`;
