import axios from "axios";
import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userInfoState } from "../atoms";
import FindPw1 from "../components/findPwComponents/findPw1.components";
import FindPw3 from "../components/findPwComponents/findPw3.components";
import FindPw4 from "../components/findPwComponents/findPw4.components";
import SignUp2 from "../components/signupComponents/signUp2.components";

function FindPw() {
  const [passStep1, setPassStep1] = useState(false);
  const [passStep2, setPassStep2] = useState(false);
  const emailAuthStatus = new URLSearchParams(window.location.search).get("status");
  const hashedData = window.location.hash.split("#")[1];
  const KEY: string = `${process.env.REACT_APP_HASH_KEY}`;
  const IV: string = `${process.env.REACT_APP_HASH_IV}`;
  const setUserInfo = useSetRecoilState(userInfoState);
  const userInfo = useRecoilValue(userInfoState);
  console.log(userInfo);
  useEffect(() => {
    if (hashedData) {
      const cipher = CryptoJS.AES.decrypt(hashedData, CryptoJS.enc.Base64.parse(KEY), {
        iv: CryptoJS.enc.Base64.parse(IV),
        mode: CryptoJS.mode.CBC,
      });
      const email = CryptoJS.enc.Utf8.stringify(cipher).split("|")[0];
      const emailToken = CryptoJS.enc.Utf8.stringify(cipher).split("|")[1];
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/check/email/auth`, {
          email: email,
          token: emailToken,
        })
        .then(
          (res) =>
            res.data.data.authorized &&
            setUserInfo((prev) => {
              return {
                ...prev,
                email,
                id: email,
                emailToken,
              };
            }),
        );
    }
  }, [hashedData]);

  if (passStep2 && emailAuthStatus && hashedData) return <FindPw4 />;
  else if (emailAuthStatus && hashedData && !passStep2) return <FindPw3 setPassStep2={setPassStep2} />;
  else if (passStep1) return <SignUp2 />;
  return <FindPw1 setPassStep1={setPassStep1} />;
}
export default FindPw;
