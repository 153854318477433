import styled from "styled-components";
import FanddleLogoForLogin from "../fanddleLogoForLogin.components";
import cuteImg from "../../images/logos/cute2.png";

interface ISetPassStep2 {
  setPassStep2: React.Dispatch<React.SetStateAction<boolean>>;
}
function FindPw3({ setPassStep2 }: ISetPassStep2) {
  return (
    <Wrapper>
      <FanddleLogoForLogin />
      <CuteImg src={cuteImg} />
      <Thankyou>감사합니다!</Thankyou>
      <GuideMsg>
        이메일 인증이 성공적으로 완료되었습니다. <br />
        이어서 비밀번호 변경을 진행해주세요.
      </GuideMsg>
      <NextBtn onClick={() => setPassStep2(true)}>이어서 진행하기</NextBtn>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 470px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 16px;
  padding: 50px 40px 40px 40px;
`;
const CuteImg = styled.img`
  display: block;
  width: 240px;
  height: 255px;
  margin: 0 auto 80px auto;
`;
const Thankyou = styled.p`
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  margin-top: 60px;
  color: #333333;
`;
const GuideMsg = styled.p`
  width: 390px;
  height: 44px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #666666;
  margin-top: 32px;
`;
const NextBtn = styled.button`
  padding: 12px;
  gap: 10px;
  width: 390px;
  height: 38px;
  background: #ff696b;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  margin-top: 80px;
`;
export default FindPw3;
