import { useForm } from "react-hook-form";
import { useInfiniteQuery } from "react-query";
import styled from "styled-components";
import FanddleLogoForLogin from "../fanddleLogoForLogin.components";
import 돋보기 from "../../images/logos/돋보기.png";
import whiteHeart from "../../images/logos/whiteHeart.png";
import { useInView } from "react-intersection-observer";
import { getCelebList } from "../../utils/apis.utils";
import { useEffect } from "react";
import SyncLoader from "react-spinners/SyncLoader";
import { useRecoilState } from "recoil";
import { userInfoState } from "../../atoms";
import axios from "axios";

function SignUp5() {
  const { register, watch } = useForm<{ name: string }>({
    defaultValues: {
      name: "",
    },
  });
  const word = watch("name");
  const { ref, inView } = useInView();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    ["celebData", word],
    ({ pageParam }) => getCelebList(pageParam, word),
    {
      getNextPageParam: (lastPage) => (!lastPage.isLastPage ? lastPage.nextPage : undefined),
      cacheTime: 5 * 60 * 1000,
    },
  );

  function addFollow(params: string) {
    setUserInfo((prev) => {
      if (prev.follow.includes(params)) {
        return { ...prev, follow: prev.follow.filter((el) => el !== params) };
      } else {
        return { ...prev, follow: prev.follow.concat(params) };
      }
    });
  }
  function requestSignUp() {
    axios.post(`${process.env.REACT_APP_BASE_URL}/signup`, userInfo).then((res) => console.log(res));
  }
  useEffect(() => {
    if (inView) fetchNextPage();
  }, [inView]);

  return (
    <Wrapper>
      <FanddleLogoForLogin />
      <p>
        내가 좋아하는 셀럽을 팔로우하여 <br />
        fanddle을 시작해보세요!
      </p>
      <InputWrapper>
        <Magnifying src={돋보기} />
        <NameInput {...register("name")} placeholder="팔로우할 셀럽을 검색하세요." />
      </InputWrapper>
      <InnerWrapper>
        {data?.pages
          ?.map((el: any) => el.res)
          .flat()
          .map((el: { name: string; thumbnail: string; uid: string }) => (
            <CelebWrapper key={el.uid} onClick={() => addFollow(el.name)}>
              <CelebImg followed={userInfo.follow.includes(el.name)} url={el.thumbnail}>
                {userInfo.follow.includes(el.name) && <WhiteHeart src={whiteHeart} />}
              </CelebImg>
              <CelebName followed={userInfo.follow.includes(el.name)}>{el.name}</CelebName>
            </CelebWrapper>
          ))}
        {isFetchingNextPage ? (
          <div style={{ position: "relative", left: "170px" }}>
            <SyncLoader size={10} color="#ff696b" />
          </div>
        ) : (
          <div style={{ width: "20px", height: "20px" }} ref={ref}></div>
        )}
      </InnerWrapper>
      <BtnWrapper>
        <button>취소</button>
        <button onClick={requestSignUp}>완료</button>
      </BtnWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 470px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 16px;
  padding: 50px 40px 40px 40px;
  font-family: "Pretendard";
  font-style: normal;
  p {
    font-weight: 400;
    width: 335px;
    font-size: 20px;
    line-height: 30px;
    color: #333333;
    margin-bottom: 24px;
  }
`;
const InputWrapper = styled.div`
  position: relative;
  input:disabled {
    background-color: #ffffff;
  }
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 30px;
`;
const NameInput = styled.input`
  width: 100%;
  border: none;
  padding: 8px 4px 8px 36px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
const Magnifying = styled.img`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 10px;
`;
const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 423px;
  overflow-y: scroll;
  gap: 23.06px 34.7px;
`;

const CelebWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;
const CelebImg = styled.div<{ url: string; followed: boolean }>`
  width: 92px;
  height: 92px;
  border-radius: 50%;
  background-image: ${(props) =>
    props.followed
      ? `linear-gradient(rgba(255, 105, 107, 0.7), rgba(255, 105, 107, 0.7)), url(${props.url})`
      : `url(${props.url})`};
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WhiteHeart = styled.img`
  display: block;
  width: 21.85px;
  height: 19.55px;
`;
const CelebName = styled.span<{ followed: boolean }>`
  margin-top: 10px;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => (props.followed ? "#ff696b" : "#333333")};
`;
const BtnWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 4px;
  button {
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    &:first-child {
      background: #ffffff;
      border: 1px solid #ececec;
      color: #aaaaaa;
    }
    &:last-child {
      background: #ff696b;
      color: #ffffff;
    }
  }
`;
export default SignUp5;
