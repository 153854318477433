import { useNavigate } from "react-router-dom";
import styled from "styled-components";
interface ICelebCard {
  imgUrl: string | undefined;
  celebName: string | undefined;
}

const CelebCard = ({ imgUrl, celebName }: ICelebCard) => {
  const navigate = useNavigate();
  return (
    <CCWrapper
      onClick={() => {
        navigate("/");
      }}>
      <CImgWrapper>
        <CelebImg src={imgUrl} alt={celebName} />
      </CImgWrapper>
      <CelebName>{celebName}</CelebName>
    </CCWrapper>
  );
};
export default CelebCard;

const CCWrapper = styled.div`
  margin-right: 20px;
  width: 144px;
  height: 202px;
  border: 1px solid #ececec;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
`;
const CImgWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
`;
const CelebImg = styled.img`
  width: 100%;
  height: fit-content;
`;
const CelebName = styled.span`
  text-decoration: none;
  width: 110px;
  height: 42px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  word-break: normal;
  color: #333333;
  margin: 0 auto;
  -webkit-line-clamp: 2;
`;
