import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle`
*{
    box-sizing: border-box;
}
    ${reset}
    html {
        font-size : 62.5%
    }
    input:focus {
        outline : none;
    }
    body {
        font-family : 'Pretendard', sans-serif;
        font-style : normal;
    }
    button {
        cursor : pointer;
    }
    a {color: #fff; text-decoration: none; outline: none} 
    

    .no-drag {-ms-user-select: none; -moz-user-select: -moz-none; -webkit-user-select: none; -khtml-user-select: none; user-select:none;};
    .poppins {
    font-family : 'Poppins', sans-serif;
}
    .swiper {
  width: 100%;
  height: 100%;
  position: relative;
}

    .swiper-pagination-progressbar.swiper-pagination-vertical, .swiper-vertical>.swiper-pagination-progressbar {
    top : 50%;
    left : 4%;
    height : 160px;
    transform : translateY(-50%);
}
    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background-color : #D9D9D9;
}
    .swiper-pagination-progressbar {
    background-color : rgba(217, 217, 217, 0.2);
}
`;

export default GlobalStyles;
