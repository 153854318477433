import { useNavigate } from "react-router-dom";
import styled from "styled-components";
interface IRecentOrder {
  group: any;
  orderedAt: string;
  orderNo: string | undefined;
  recvName: string | undefined;
  isLast: boolean;
}

const RecentOrderBox = ({ group, orderedAt, orderNo, recvName, isLast }: IRecentOrder) => {
  const navigate = useNavigate();
  const reprice = Math.floor(group.price).toLocaleString("ko-KR");
  const orderDate = orderedAt.slice(0, 10).split("-").join(".");
  const newQty = group.qty - 1;
  return (
    <OrderBox isLast={isLast}>
      <ItemImg>
        <img src={group.item.thumbnail} alt={group.itemName} />
      </ItemImg>
      <OBContainer
        onClick={() => {
          navigate("/");
        }}>
        <OBCWrapper>
          <OrderDate>{orderDate}</OrderDate>
          <OrderNumber>{orderNo}</OrderNumber>
        </OBCWrapper>
        <Recv>To. {recvName}</Recv>
        <INWrapper>
          <ItemNameWrapper>
            <ItemName>{group.itemName}</ItemName>
            {newQty !== 0 && <OrderQty>외 {newQty}건</OrderQty>}
          </ItemNameWrapper>
          <PriceWrapper>
            <Price>{reprice}원</Price>
          </PriceWrapper>
        </INWrapper>
      </OBContainer>
    </OrderBox>
  );
};

export default RecentOrderBox;

const OrderBox = styled.div<{ isLast: boolean }>`
  border-top: ${(props) => (props.isLast ? "1px solid #ECECEC" : "none")};
  padding-top: ${(props) => (props.isLast ? "10px" : "none")};
  display: flex;
  margin-bottom: 10px;
`;
const ItemImg = styled.div`
  width: 120px;
  height: 120px;
  background: #d9d9d9;
  margin-right: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const OBContainer = styled.div`
  width: calc(100% - 130px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 120px;
  cursor: pointer;
`;
const OBCWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
`;
const OrderDate = styled.span`
  margin-right: 8px;
`;
const OrderNumber = styled.span`
  text-align: right;
  color: #aaaaaa;
`;

const Recv = styled.p`
  /* width: 670px; */
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;
const INWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* width: 670px; */
`;
const ItemName = styled.span`
  text-decoration: none;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
  margin-right: 10px;
`;
const OrderQty = styled.span`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #888888;
`;
const Price = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  color: #333333;
`;
const ItemNameWrapper = styled.div`
  flex: 1;
  height: 60px;
`;
const PriceWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
