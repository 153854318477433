import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/header.components";
import Aboutfanddle from "./pages/aboutFanddle.pages";
import Aboutus from "./pages/aboutus.pages";
import MyPage from "./pages/myPage.pages";
import SignUp from "./pages/signUp.pages";
import FindPw from "./pages/findPw.pages";
import Profile from "./pages/profile.pages";

function Router() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Aboutfanddle />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/mypage" element={<MyPage />} />
        <Route path="/findPw" element={<FindPw />} />
        <Route path="/mypage/profile" element={<Profile />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
