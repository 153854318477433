import React from "react";
import styled from "styled-components";
import image from "../../images/aboutFanddle/image4.jpg";
import { idxProps } from "./section1.homeComponents";
import { BoldText, NormalText } from "./section2.homeComponents";

function Section3({ idx }: idxProps) {
  const animateStart = idx === 2;
  return (
    <Wrapper>
      <InnerWrapper>
        <BoldText animateStart={animateStart}>
          셀럽에게 마음을 전달하고
          <br /> <strong>답장을 받아보세요</strong>
        </BoldText>
        <NormalText animateStart={animateStart}>
          언어가 달라도 걱정말아요
          <br />
          번역 기능을 통해 나의 메시지가 전달될 수 있어요 !
        </NormalText>
      </InnerWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: url(${image});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding-left: 19.11vw;
`;
const InnerWrapper = styled.div``;

export default React.memo(Section3);
