import React from "react";
import styled from "styled-components";
import { AppleLogo, ButtonWrapper, idxProps, Message1, MessageWrapper, PlayLogo } from "./section1.homeComponents";
import Footer from "../footer.components";
import mockUp from "../../images/aboutFanddle/mockup.jpg";
function Section5({ idx }: idxProps) {
  const animateStart = idx === 4;
  return (
    <Wrapper>
      <InnerWrapper>
        <MessageWrapper style={{ top: "35%" }}>
          <Message1 animateStart={animateStart}>
            <strong className="poppins">fanddle </strong>에서
            <br />
            특별한 추억을 쌓아보세요
          </Message1>
        </MessageWrapper>
        <ButtonWrapper animateStart={animateStart} style={{ top: "48%" }}>
          <a href="https://apps.apple.com/kr/app/fanddle-cheer-for-your-bias/id1494667561">
            <button>
              <AppleLogo />
              <span>App store</span>
            </button>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.fanddle.app&gl=US">
            <button>
              <PlayLogo />
              <span>Google Play</span>
            </button>
          </a>
        </ButtonWrapper>
      </InnerWrapper>
      <MockUpWrapper>
        <img src={mockUp} alt="mockup screen" />
      </MockUpWrapper>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #232323;
  display: flex;
  align-items: center;
`;
const InnerWrapper = styled.div`
  display: flex;
`;
const MockUpWrapper = styled.div`
  position: absolute;
  right: 10%;
  top: 10%;
  img {
    width: 30.7vw;
  }
`;

export default React.memo(Section5);
