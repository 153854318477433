import styled from "styled-components";
import FanddleLogoForLogin from "./fanddleLogoForLogin.components";
import 돋보기 from "../images/logos/돋보기.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { IUserForm } from "./signupComponents/signUp4.components";
import { useSetRecoilState } from "recoil";
import { userInfoState } from "../atoms";
interface IProps {
  modalUp: boolean;
  setModalUp: React.Dispatch<React.SetStateAction<boolean>>;
  input: JSX.Element;
  watch: UseFormWatch<IUserForm>;
  setValue: UseFormSetValue<IUserForm>;
}
interface ICountry {
  name: string;
  flag: string;
  uid: string;
}

function SelectCountry({ modalUp, setModalUp, input, watch, setValue }: IProps) {
  const [countryInfo, setCountryInfo] = useState<ICountry[]>([]);
  const [selected, setSelected] = useState({ name: "", uid: "" });
  const setUserInfo = useSetRecoilState(userInfoState);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/country/list?type=0`).then((data) =>
      setCountryInfo(
        data.data.data.countrys.map((el: ICountry) => {
          return { uid: el.uid, flag: el.flag, name: el.name };
        }),
      ),
    );
  }, []);

  return (
    <Sub modalUp={modalUp}>
      <FanddleLogoForLogin />
      <h1>국가 선택</h1>
      <div style={{ position: "relative" }}>
        <Magnifying src={돋보기} />
        {input}
      </div>
      <CountriesWrapper>
        {countryInfo
          .filter((el) => {
            return el.name.includes(watch("country"));
          })
          .map((el) => (
            <CountryWrapper
              key={el.uid}
              selected={el.name === selected.name}
              onClick={() => {
                setSelected((prev) => {
                  if (prev.name === el.name) {
                    setValue("country", "", { shouldDirty: true });
                    return prev;
                  } else {
                    return { ...prev, name: el.name, uid: el.uid };
                  }
                });
              }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CountryFlag>{el.flag}</CountryFlag>
                <CountryName>{el.name}</CountryName>
              </div>
              <CheckBox
                selected={el.name === selected.name}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="#ECECEC" />
                <path
                  d="M7 12.5L10.5 16L17 8"
                  stroke="#AAAAAA"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </CheckBox>
            </CountryWrapper>
          ))}
      </CountriesWrapper>
      <BtnWrapper>
        <button
          onClick={() => {
            setSelected({ name: "", uid: "" });
            setValue("country", "", { shouldDirty: true });
            setModalUp(false);
          }}>
          취소
        </button>
        <button
          onClick={() => {
            setValue("country", selected.name, { shouldDirty: true });
            setUserInfo((prev) => {
              return { ...prev, countryUid: selected.uid };
            });
            setModalUp(false);
          }}>
          완료
        </button>
      </BtnWrapper>
    </Sub>
  );
}

const Sub = styled.div<{ modalUp: boolean }>`
  background: #ffffff;
  border-radius: 16px;
  padding: 50px 40px 40px 40px;
  height: 790px;
  transform: ${(props) => (props.modalUp ? "translateY(-790px)" : "translateY(0px)")};
  transition: all 0.5s ease-in-out;
  h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #333333;
  }
`;
const BtnWrapper = styled.div`
  display: flex;
  gap: 4px;
  button {
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    &:first-child {
      background: #ffffff;
      border: 1px solid #ececec;
      color: #aaaaaa;
    }
    &:last-child {
      background: #ff696b;
      color: #ffffff;
    }
  }
`;
const Magnifying = styled.img`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 26px;
`;
const CountriesWrapper = styled.div`
  height: 408px;
  margin-bottom: 20px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const CountryWrapper = styled.div<{ selected: boolean }>`
  height: 68px;
  background: ${(props) => (props.selected ? "rgba(255, 105, 107, 0.1)" : "#FFFFFF")};
  padding: 16px 16px 16px 24px;
  display: flex;
  border-radius: 10px;
  width: 368px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
const CountryFlag = styled.span`
  font-size: 36px;
  display: block;
  margin-right: 20px;
`;
const CountryName = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
`;
const CheckBox = styled.svg<{ selected: boolean }>`
  width: 24px;
  height: 24px;
  rect {
    fill: ${(props) => props.selected && "#FF696B"};
  }
  path {
    stroke: ${(props) => props.selected && "#F5F5F5"};
  }
`;
export default SelectCountry;
