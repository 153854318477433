import { useEffect } from "react";
import styled from "styled-components";
type pwType = {
  pw: string;
  setIsPwValid: React.Dispatch<React.SetStateAction<boolean>>;
};
function PwProgressBar({ pw, setIsPwValid }: pwType) {
  let score = 0;
  let capital = 0;
  let special = 0;
  let number = false;
  const specialArr = ["!", "@", "#", "$", "%", "^", "&", "*", "?"];

  for (let i = 0; i < pw.length; i++) {
    if (64 < pw.charCodeAt(i) && pw.charCodeAt(i) < 91) {
      capital++;
    }
    if (specialArr.indexOf(pw[i]) >= 0) {
      special++;
    }
    if (!isNaN(Number(pw[i]))) {
      number = true;
    }
  }
  //10자리 이상 점수 계산
  if (pw.length > 9) {
    score++;
  }
  //숫자가 있는지 점수 계산
  if (number) {
    score++;
  }
  //대문자 점수 계산
  if (capital >= 2) {
    score += 2;
    console.log("대문자 2개", score);
  } else if (capital === 1) {
    score++;
  }

  if (special >= 2) {
    score += 2;
  } else if (special) {
    score++;
  }

  useEffect(() => {
    if (score >= 3) {
      setIsPwValid(true);
    }
  }, [score]);
  return (
    <Wrapper>
      <FakeDiv>
        <ColorDiv score={score} />
      </FakeDiv>
      <Msg score={score}>
        {score <= 2 ? "위험 : 통과불가" : score === 3 ? "보통" : score === 4 ? "좋음" : "아주 좋음"}
      </Msg>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
const FakeDiv = styled.div`
  width: 100px;
  margin-top: 19px;
  height: 2px;
  background: #d9d9d9;
  margin-right: 10px;
`;
const ColorDiv = styled.div<{ score: number }>`
  width: ${(props) => (props.score <= 2 ? "25%" : props.score === 3 ? "50%" : props.score === 4 ? "75%" : "100%")};
  height: 2px;
  background: ${(props) =>
    props.score <= 2 ? "#FF6363" : props.score === 3 ? "#F2BB3B" : props.score === 4 ? "#57E18F" : "#63AEFF"};
  transition: all 0.3s ease-in-out;
`;
const Msg = styled.p<{ score: number }>`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  bottom: -8px;
  color: ${(props) =>
    props.score <= 2 ? "#FF6363" : props.score === 3 ? "#F2BB3B" : props.score === 4 ? "#57E18F" : "#63AEFF"};
`;

export default PwProgressBar;
