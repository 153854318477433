import { useNavigate } from "react-router-dom";
import styled from "styled-components";
interface IProduct {
  imgSrc: string | undefined;
  brandName: string | undefined;
  itemName: string | undefined;
  price: string | undefined;
}
function Product({ imgSrc, brandName, itemName, price }: IProduct) {
  const navigate = useNavigate();
  const reprice = Math.floor(Number(price)).toLocaleString("ko-KR");
  return (
    <Item
      onClick={() => {
        navigate("/");
      }}>
      <ItemImg src={imgSrc} alt={itemName} />
      <BrandName>{brandName}</BrandName>
      <ItemName href="#">{itemName} </ItemName>
      <ItemPrice>{reprice}원</ItemPrice>
    </Item>
  );
}
const Item = styled.div`
  width: 183px;
  cursor: pointer;
`;
const ItemImg = styled.img`
  display: block;
  width: 100%;
  height: 183px;
  margin-bottom: 10px;
  object-fit: cover;
`;
const BrandName = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 105, 107, 0.8);
  margin-bottom: 8px;
`;
const ItemName = styled.a`
  text-decoration: none;
  font-weight: 500;
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: normal;
  width: 183px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ItemPrice = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #333333;
`;
export default Product;
