import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { userInfoState } from "../../atoms";
import FanddleLogoForLogin from "../fanddleLogoForLogin.components";
import ResetBtn from "../resetBtn.components";
import SelectCountry from "../selectCountry.components";
import { userInfo } from "../../pages/signUp.pages";

export interface IUserForm {
  name: string;
  nickname: string;
  country: string;
  recommender: string;
}
interface setUserInfoProp {
  setPassSignUp4: React.Dispatch<React.SetStateAction<boolean>>;
}

function SignUp4({ setPassSignUp4 }: setUserInfoProp) {
  const setUserInfo = useSetRecoilState(userInfoState);
  const [nickNamePass, setNickNamePass] = useState(false);
  const [modalUp, setModalUp] = useState(false);
  const [deemedUp, setDeemedUp] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<IUserForm>({
    defaultValues: {
      name: "",
      nickname: "",
      country: "",
      recommender: "",
    },
  });
  const nickNameValue = watch("nickname");
  const inputArr = Object.keys(dirtyFields);
  const isNameFilled = inputArr.includes("name");
  const isNickNameFilled = inputArr.includes("nickname");
  const isCountryFilled = inputArr.includes("country");
  const isRecommenderFilled = inputArr.includes("recommender");
  const canPressBtn = nickNamePass && isNameFilled && isNickNameFilled && isCountryFilled;
  const onSubmit = handleSubmit((data) => {
    setUserInfo((prev) => {
      return { ...prev, name: data.name, nickname: data.nickname };
    });
    setPassSignUp4(true);
  });

  function inspectNickName() {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/check/nickname?nickname=${nickNameValue}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TEST_TOKEN}`,
      },
    });
  }

  useEffect(() => {
    function isNickNameValid() {
      inspectNickName().then((data) => {
        if (data.data.data.usable) {
          setNickNamePass(true);
        } else {
          setNickNamePass(false);
        }
      });
    }
    isNickNameValid();
  }, [nickNameValue]);

  useEffect(() => {
    if (modalUp) {
      setDeemedUp(true);
    } else if (!modalUp) {
      setTimeout(() => {
        setDeemedUp(false);
      }, 450);
    }
  }, [modalUp]);
  return (
    <Wrapper>
      <Main>
        <FanddleLogoForLogin />
        <UserForm onSubmit={onSubmit}>
          <GuideMsg>
            이메일 인증이 완료되었습니다.
            <br />
            회원 정보를 입력해주세요.
          </GuideMsg>
          <label htmlFor="name">이름</label>
          <InputWrapper>
            <NameInput id="name" placeholder="이름을 입력해주세요." {...register("name")} filled={isNameFilled} />
            <ResetBtn top={7} setValue={setValue} target="name" />
          </InputWrapper>
          <label htmlFor="nickname">닉네임</label>
          <InputWrapper>
            <NickInput
              id="nickname"
              placeholder="닉네임을 입력해주세요."
              {...register("nickname")}
              filled={isNickNameFilled}
              nickNamePass={nickNamePass}
            />
            <ResetBtn top={7} setValue={setValue} target="nickname" />
            {isNickNameFilled && !nickNamePass && <WarningMsg>이미 사용중인 닉네임입니다.</WarningMsg>}
          </InputWrapper>
          <label htmlFor="country">국가</label>
          <InputWrapper
            onClick={() => {
              setModalUp(true);
            }}>
            <CountryInput
              style={{ cursor: "pointer" }}
              disabled
              id="country"
              placeholder="국가를 선택해주세요."
              type="text"
              {...register("country")}
              filled={isCountryFilled}
            />
            <svg
              style={{ position: "absolute", right: "4px", top: "14px", cursor: "pointer" }}
              width="16"
              height="9"
              viewBox="0 0 16 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 1L7.8 8L15 0.999999"
                stroke="#AAAAAA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </InputWrapper>
          <label htmlFor="recommender">추천인 코드(선택)</label>
          <InputWrapper>
            <RecommendInput
              id="recommender"
              placeholder="추천인 코드를 입력해주세요."
              {...register("recommender")}
              filled={isRecommenderFilled}
            />
            <ResetBtn top={7} setValue={setValue} target="recommender" />
          </InputWrapper>
          <NextBtn disabled={!canPressBtn} canPressBtn={canPressBtn}>
            다음
          </NextBtn>
        </UserForm>
        {deemedUp && <Dimmed />}
      </Main>
      <SelectCountry
        modalUp={modalUp}
        setModalUp={setModalUp}
        watch={watch}
        setValue={setValue}
        input={
          <CountryInput
            style={{ marginTop: "16px", marginBottom: "20px", paddingLeft: "25px" }}
            placeholder="국가명을 검색하세요."
            {...register("country")}
            filled={isCountryFilled}
          />
        }
      />
    </Wrapper>
  );
}
//전체 영역
const Wrapper = styled.div`
  width: 470px;
  margin: 0 auto;
  height: 780px;
  overflow: hidden;
  border-radius: 16px;
  position: relative;
`;
//보이는 영역
const Main = styled.div`
  background: #ffffff;
  border-radius: 16px;
  padding: 50px 40px 40px 40px;
  position: relative;
`;
const Dimmed = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;
const GuideMsg = styled.p`
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  margin-bottom: 32px;
`;
const UserForm = styled.form`
  label {
    display: block;
    margin-bottom: 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }
`;
const InputWrapper = styled.div`
  margin-bottom: 40px;
  position: relative;
  input:disabled {
    background-color: #ffffff;
  }
`;
const NameInput = styled.input<{ filled: boolean }>`
  &::placeholder {
    color: #aaaaaa;
  }
  &::-ms-input-placeholder {
    color: #aaaaaa;
  }
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 5px;
  border: none;
  border-bottom: ${(props) => (props.filled ? "1px solid #333333" : "1px solid #AAAAAA")};
  width: 100%;
`;
const NickInput = styled(NameInput)<{ filled: boolean; nickNamePass: boolean }>`
  border-bottom: ${(props) =>
    props.filled && !props.nickNamePass
      ? "1px solid #FF2323"
      : !props.filled
      ? "1px solid #AAAAAA"
      : "1px solid #333333"};
`;
export const CountryInput = styled(NameInput)<{ filled: boolean }>`
  border-bottom: ${(props) => (props.filled ? "1px solid #333333" : "1px solid #AAAAAA")};
`;
const RecommendInput = styled(NameInput)<{ filled: boolean }>`
  border-bottom: ${(props) => (props.filled ? "1px solid #333333" : "1px solid #AAAAAA")};
`;
const NextBtn = styled.button<{ canPressBtn: boolean }>`
  padding: 12px;
  gap: 10px;
  width: 390px;
  height: 38px;
  border-radius: 4px;
  border: none;
  margin-top: 40px;
  background: ${(props) => (props.canPressBtn ? "#ff696b" : "#F5F5F5")};
  color: ${(props) => (props.canPressBtn ? "#ffffff" : "#CECECE")};
  transition: all 0.3s ease-in-out;
`;
const WarningMsg = styled.p`
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ff2323;
`;

export default SignUp4;
