import styled, { css, keyframes } from "styled-components";
import { idxProps } from "./section1.homeComponents";
import { BoldText, LeftWrapper, NormalText, Wrapper } from "./section2.homeComponents";
import supportBoard from "../../images/aboutFanddle/supportBoard.jpg";
import React from "react";
function Section4({ idx }: idxProps) {
  const animateStart = idx === 3;

  return (
    <Wrapper>
      <LeftWrapper>
        <BoldText animateStart={animateStart} style={{ fontWeight: "100" }}>
          세계 각국의 팬들과 <br /> <strong>함께 응원해요 !</strong>
        </BoldText>
        <NormalText animateStart={animateStart}>
          <FanddleLogo animateStart={animateStart} className="poppins">
            fanddle
          </FanddleLogo>{" "}
          에서는 커피차, 지하철 광고, 기부 등
          <br />
          다양하게 내 스타를 응원할 수 있어요
        </NormalText>
      </LeftWrapper>
      <RightWrapper>
        <SupportBoardImg src={supportBoard} alt="" animateStart={animateStart} />
      </RightWrapper>
    </Wrapper>
  );
}
const ImageSlide = keyframes`
  0% {
    transform: translateY(40%);
  }
  100% {
    transform: translateY(-25%);
  }
`;
const FanddleLogoFade = keyframes`
  0% {
    color : transparent;
  }
  100% {
    color : #FF696B;
  }
`;
const RightWrapper = styled.div`
  width: 36vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;
const SupportBoardImg = styled.img<{ animateStart: boolean }>`
  transform: translateY(40%);
  ${(props) =>
    props.animateStart &&
    css`
      animation: ${ImageSlide} 5s cubic-bezier(0.7, 0, 0.3, 1);
    `}
  animation-fill-mode: forwards;
`;
const FanddleLogo = styled.strong<{ animateStart: boolean }>`
  ${(props) =>
    props.animateStart &&
    css`
      animation: ${FanddleLogoFade} 1.5s 0.5s ease-in-out;
    `}
  animation-fill-mode: forwards;
`;
export default React.memo(Section4);
