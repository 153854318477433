import styled, { css, keyframes } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
import React, { useEffect, useState } from "react";
import appleKorea from "../../images/logos/applekorea1.png";
import googlePlay from "../../images/logos/googleplay1.png";
import Image1 from "../../images/aboutFanddle/image1.jpg";
import Image3 from "../../images/aboutFanddle/image3.jpg";
import "swiper/css/effect-fade";
import "swiper/css";

export interface idxProps {
  idx: number;
}

function SliderImg({ source }: { source: string }) {
  return <SliderImage src={source} alt="fanddle homepage view" />;
}

function Section1({ idx }: idxProps) {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
  const animateStart = idx === 0 ? true : false;

  useEffect(() => {
    if (animateStart) {
      swiperInstance?.autoplay.start();
    } else {
      swiperInstance?.autoplay.stop();
    }
  }, [animateStart, swiperInstance?.autoplay]);

  return (
    <>
      <Main>
        <MessageWrapper>
          <Message1 animateStart={animateStart}>
            나와 셀럽 사이의 거리,
            <br />
            <strong className="poppins">fanddle </strong>에서 가까워집니다
          </Message1>
        </MessageWrapper>
        <div>
          <ButtonWrapper animateStart={animateStart}>
            <a href="https://apps.apple.com/kr/app/fanddle-cheer-for-your-bias/id1494667561">
              <button>
                <AppleLogo />
                <span>App store</span>
              </button>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.fanddle.app&gl=US">
              <button>
                <PlayLogo />
                <span>Google Play</span>
              </button>
            </a>
          </ButtonWrapper>
        </div>
        <Swiper
          slidesPerView={1}
          speed={700}
          threshold={800}
          autoplay={{ disableOnInteraction: false }}
          effect={"fade"}
          loop={true}
          modules={[EffectFade, Autoplay]}
          onSwiper={setSwiperInstance}>
          <SwiperSlide>
            <SliderImg source={Image1} />
          </SwiperSlide>
          <SwiperSlide>
            <SliderImg source={Image3} />
          </SwiperSlide>
        </Swiper>
      </Main>
    </>
  );
}

export const Message1Fade = keyframes`
   0% {
    opacity: 0;
    transform : translateY(50px);
  }
  100% {
    opacity: 1;
    transform : translateY(0);
  }
`;

export const Message2Fade = keyframes`
  0% {
    color : transparent;
  }
  100% {
    color : rgba(255, 255, 255, 0.6);
  }
`;

const buttonFadeIn = keyframes`
  0% {
    opacity : 0;
  }
  100% {
    opacity : 1;
  }
`;

const Main = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const MessageWrapper = styled.div`
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 5;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-left: 19.11vw;
`;
export const Message1 = styled.p<{ animateStart: boolean }>`
  color: #ffffff;
  font-weight: 600;
  font-size: 3.125vw;
  line-height: 150%;
  strong {
    color: #ff696b;
    font-weight: 700;
  }
  ${(props) =>
    props.animateStart &&
    css`
      animation: ${Message1Fade} 1s ease-in-out;
    `}
`;

export const ButtonWrapper = styled.div<{ animateStart: boolean }>`
  position: absolute;
  top: 53%;
  margin-left: 19.11vw;
  z-index: 5;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 1.6rem;
  opacity: 0;
  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.6rem 1.2rem;
    gap: 0.4rem;
    width: 9.75vw;
    background: #444444;
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(30, 34, 37, 0.91);
    cursor: pointer;
    span {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 600;
      font-size: 0.97vw;
      line-height: 1.8rem;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
    }
  }
  ${(props) =>
    props.animateStart &&
    css`
      animation: ${buttonFadeIn} 1s 1s ease-in-out;
    `}
  animation-fill-mode: forwards;
`;
export const AppleLogo = styled.div`
  width: 24px;
  height: 24px;
  background: url(${appleKorea});
  position: relative;
  top: -2px;
`;
export const PlayLogo = styled(AppleLogo)`
  background: url(${googlePlay});
`;
const SliderImage = styled.img`
  width: 100%;
  height: 100%;
`;
export default React.memo(Section1);
