import { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import SignUp1 from "../components/signupComponents/signUp1.components";
import SignUp2 from "../components/signupComponents/signUp2.components";
import SignUp3 from "../components/signupComponents/signUp3.components";
import SignUp4 from "../components/signupComponents/signUp4.components";
import SignUp5 from "../components/signupComponents/signUp5.components";
import axios from "axios";
import { useRecoilState } from "recoil";
import { userInfoState } from "../atoms";
export type userInfo = {
  email: string;
  id: string;
  accountType: number;
  emailToken: string;
  name: string;
  username: string;
  termsUse: number;
  termsPrivacy: number;
  countryUid: string;
};
function SignUp() {
  const [passSignUp1, setPassSignUp1] = useState(false);
  const [passSignUp3, setPassSignUp3] = useState(false);
  const [passSignUp4, setPassSignUp4] = useState(false);
  const [userInfo1, setUserInfo1] = useRecoilState(userInfoState);
  const emailAuthStatus = new URLSearchParams(window.location.search).get("status");
  const hashedData = window.location.hash.split("#")[1];
  const KEY: string = `${process.env.REACT_APP_HASH_KEY}`;
  const IV: string = `${process.env.REACT_APP_HASH_IV}`;

  useEffect(() => {
    if (hashedData) {
      const cipher = CryptoJS.AES.decrypt(hashedData, CryptoJS.enc.Base64.parse(KEY), {
        iv: CryptoJS.enc.Base64.parse(IV),
        mode: CryptoJS.mode.CBC,
      });
      const email = CryptoJS.enc.Utf8.stringify(cipher).split("|")[0];
      const emailToken = CryptoJS.enc.Utf8.stringify(cipher).split("|")[1];
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/check/email/auth`, {
          email: email,
          token: emailToken,
        })
        .then(
          (res) =>
            res.data.data.authorized &&
            setUserInfo1((prev) => {
              return {
                ...prev,
                email,
                id: email,
                emailToken,
              };
            }),
        );
    }
  }, [hashedData]);

  if (passSignUp1) return <SignUp2 />;
  if (emailAuthStatus === "1" && !passSignUp3) return <SignUp3 setPassSignUp3={setPassSignUp3} />;
  if (passSignUp3 && !passSignUp4) return <SignUp4 setPassSignUp4={setPassSignUp4} />;
  if (passSignUp4) return <SignUp5 />;
  else return <SignUp1 setPassSignUp1={setPassSignUp1} />;
}
export default SignUp;
