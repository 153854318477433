import styled from "styled-components";
import FanddleLogoForLogin from "../fanddleLogoForLogin.components";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useSetRecoilState } from "recoil";
import { userInfoState } from "../../atoms";

interface ISetPassStep1 {
  setPassStep1: React.Dispatch<React.SetStateAction<boolean>>;
}

function FindPw1({ setPassStep1 }: ISetPassStep1) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const setUserInfo = useSetRecoilState(userInfoState);
  const isEmail = watch("email").match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g) !== null;
  const isDirty = Object.keys(dirtyFields).includes("email");

  const onSubmit = handleSubmit((data) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/signin/guest`, {
        lang: "ko",
      })
      .then((res) => res.data.data.guestToken)
      .then((res) =>
        axios.post(
          `${process.env.REACT_APP_BASE_URL}/send/password/auth`,
          {
            email: data.email,
            isWeb: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${res}`,
            },
          },
        ),
      )
      .then((res) => {
        if (!res.data.message) {
          setUserInfo((prev) => {
            return { ...prev, email: data.email };
          });
          setPassStep1(true);
        }
      });
  });

  function cleanInput() {
    setValue("email", "", { shouldDirty: true });
  }

  return (
    <Wrapper>
      <FanddleLogoForLogin />
      <GuideMsg>
        가입한 이메일을 <br />
        입력해주세요.
      </GuideMsg>
      <form onSubmit={onSubmit}>
        <InputWrapper isEmail={isEmail} isDirty={isDirty}>
          <label htmlFor="email">이메일</label>
          <input id="email" placeholder="가입한 이메일을 입력해주세요." {...register("email")} />
          <ResetBtn
            onClick={cleanInput}
            active={isDirty}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
              x="20.2246"
              y="4.95117"
              width="21.5982"
              height="1.5"
              rx="0.75"
              transform="rotate(135 20.2246 4.95117)"
              fill="#AAAAAA"
            />
            <rect
              x="4.95264"
              y="3.89062"
              width="21.5982"
              height="1.5"
              rx="0.75"
              transform="rotate(45 4.95264 3.89062)"
              fill="#AAAAAA"
            />
          </ResetBtn>
          {isDirty && !isEmail && <WarningMsg>올바른 이메일 형식이 아닙니다.</WarningMsg>}
        </InputWrapper>
        <BtnWrapper isEmail={isEmail}>
          <button>확인</button>
        </BtnWrapper>
      </form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 470px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 16px;
  padding: 50px 40px 40px 40px;
  & > svg {
    display: block;
    margin: 0 auto;
    margin-bottom: 69.52px;
  }
`;
const GuideMsg = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  margin-bottom: 38px;
`;
const InputWrapper = styled.div<{ isEmail: boolean; isDirty: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 80px;
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #333333;
    margin-bottom: 16px;
  }
  input {
    &::placeholder {
      color: #aaaaaa;
    }
    &::-ms-input-placeholder {
      color: #aaaaaa;
    }
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 5px;
    border: none;
    margin-bottom: 10px;
    border-bottom: ${(props) =>
      props.isDirty && !props.isEmail
        ? "1px solid #FF6363"
        : props.isDirty
        ? "1px solid #333333"
        : "1px solid #aaaaaa"};
  }
`;
const ResetBtn = styled.svg<{ active: boolean }>`
  position: absolute;
  right: 4px;
  width: 20px;
  height: 20px;
  top: 38px;
  cursor: pointer;
  color: tomato;
  rect {
    fill: ${(props) => (props.active ? "#333333" : "#AAAAAA")};
    transition: fill 0.3s ease-in-out;
  }
`;
const WarningMsg = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ff6363;
`;
const BtnWrapper = styled.div<{ isEmail: boolean }>`
  button {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    cursor: ${(props) => (!props.isEmail ? "default" : "pointer")};
    background: ${(props) => (!props.isEmail ? "#fff0f0" : "#ff696b")};
    color: ${(props) => (!props.isEmail ? "#ff696b" : "#fff0f0")};
  }
`;
export default FindPw1;
