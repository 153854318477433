import Router from "./router";
import { RecoilRoot } from "recoil";
import { HelmetProvider } from "react-helmet-async";
import GlobalStyles from "./globalStyles";
import { QueryClient, QueryClientProvider } from "react-query";
import "swiper/css/bundle";
const queryClient = new QueryClient();
function App() {
  return (
    <>
      <GlobalStyles />
      <HelmetProvider>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <Router />
          </QueryClientProvider>
        </RecoilRoot>
      </HelmetProvider>
    </>
  );
}

export default App;
