import styled, { keyframes } from "styled-components";
import img1 from "../images/aboutUs/aboutusBackground1.png";
import img2 from "../images/aboutUs/aboutusBackground2.png";
import img3 from "../images/aboutUs/aboutusBackground3.png";
import img4 from "../images/aboutUs/aboutusBackground4.png";
import plus from "../images/logos/plus.png";
import US from "../images/flags/US.png";
import Canada from "../images/flags/Canada.png";
import Austrailia from "../images/flags/Austrailia.png";
import British from "../images/flags/British.png";
import Dubai from "../images/flags/Dubai.png";
import Germany from "../images/flags/Germany.png";
import France from "../images/flags/France.png";
import Spain from "../images/flags/Spain.png";
import Mexico from "../images/flags/Mexico.png";
import Japan from "../images/flags/Japan.png";
import Taiwan from "../images/flags/Taiwan.png";
import Hongkong from "../images/flags/Hongkong.png";
import China from "../images/flags/China.png";
import Singapore from "../images/flags/Singapore.png";
import Malaysia from "../images/flags/Malaysia.png";
import India from "../images/flags/India.png";
import Indonesia from "../images/flags/Indonesia.png";
import Y2022 from "../images/logos/timeLine/2022.png";
import Y2021 from "../images/logos/timeLine/2021.png";
import Y2020 from "../images/logos/timeLine/2020.png";
import Y2019 from "../images/logos/timeLine/2019.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
function Aboutus() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>팬들 - 회사소개</title>
      </Helmet>
      <Main>
        <p>
          우리는 기술과 콘텐츠로 <br />
          글로벌 팬덤 시장을 혁신하고자 합니다.
        </p>
        <svg width="34" height="20" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2 2.5L16.5714 17.5L32 2.5"
            stroke="#AAAAAA"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Main>
      <Wrapper>
        <InnerWrapper>
          <Sec1>
            <p>
              전 세계 팬들과 셀럽을 연결해주며
              <br /> 그들에게 특별한 팬 경험, 기억, 추억을 선사합니다.
            </p>
            <Sec1InWrap>
              <SloganWrapper>
                <p>Connect</p>
                <p>Experience</p>
                <p>Memories</p>
              </SloganWrapper>
              <img src={img2} alt="fandom" />
            </Sec1InWrap>
            <Sec1ImgWrapper>
              <img src={img3} alt="fandom 2" />
              <img src={img4} alt="Celeb performing" />
            </Sec1ImgWrapper>
          </Sec1>
          <Sec2>
            <OurBlah>Our Mission</OurBlah>
            <Title>"팬덤과 감성, 마음을 연결하는 글로벌 콘텐츠"</Title>
            <p>
              "국경 없이 마음을 연결하자"는 미션으로 모바일 앱을 통해 셀럽과의 교류 및 소통은 물론 나아가 문화, 산업 등
              다방면에서 글로벌 팬들에게 새로운 팬 경험과 가치를 제공하고 있습니다. 차별화된 팬덤 경험과 기술을 바탕으로
              전 세계 팬덤 시장을 연결하는 서비스로 수많은 글로벌 팬들이 공감하고 동의하는 "팬덤 서비스의 슈퍼 앱"으로서
              역할로 팬덤 시장을 리딩하여 시장 변화를
              <br />
              이끌어 가고 있습니다.
            </p>
          </Sec2>
          <Sec3>
            <OurBlah>Our Services</OurBlah>
            <Title>우리는 이런 서비스들을 제공합니다.</Title>
            <ServiceWrapper>
              <Circle>Entertainment</Circle>
              <img src={plus} alt="plus_image" />
              <Circle>E-Commerce</Circle>
              <img src={plus} alt="plus_image" />
              <Circle>Merchandising</Circle>
            </ServiceWrapper>
            <ServiceDetailWrapper>
              <div>
                <p>선물하기 솔루션</p>
                <p>
                  팬들은 셀럽에게 직접 마음을 담은 메세지와 선물을 전하고 셀럽은 자신이 원하는 때에 원하는 장소로 바로
                  선물을 받을 수 있어요.
                </p>
              </div>
              <div>
                <p>커스텀 제작</p>
                <p>일대일 오더메이드 제작된 특별한 커스텀 선물로 팬들과 셀럽에게 소중하고 특별한 추억을 선물해요</p>
              </div>
              <div>
                <p>다양한 서포트</p>
                <p>선한 영향력을 바탕으로 기부, 쌀 화환, 커피차 등 성숙한 팬덤 문화를 만들기 위해 앞장서요.</p>
              </div>
            </ServiceDetailWrapper>
          </Sec3>
          <Sec4>
            <Sec4Left>
              <OurBlah>Our History</OurBlah>
              <Title>
                셀럽과 팬들을
                <br />
                이어주기 위한
                <br />
                패니지먼트의 여정은
                <br />
                계속됩니다.
              </Title>
            </Sec4Left>
            <Sec4Right>
              <BoxType1>
                <BAchieve className="point">
                  팬들 서비스 글로벌 17개국 이용
                  <Year2022Box>
                    <Year src={Y2022} />
                    <Dot />
                  </Year2022Box>
                  <FlagsWrapper>
                    <div>
                      {Global17.slice(0, 9).map((el) => (
                        <img key={el} src={el} alt="flags" />
                      ))}
                    </div>
                    <div>
                      {Global17.slice(9).map((el) => (
                        <img key={el} src={el} alt="flags" />
                      ))}
                    </div>
                  </FlagsWrapper>
                </BAchieve>
                <SAchieve>Pre-A 시리즈 투자유치</SAchieve>
                <SAchieve>미국 법인 설립</SAchieve>
              </BoxType1>
              <BoxType2>
                <Date>2022.07</Date>
                <SAchieve>500global Taiwan 선정</SAchieve>
              </BoxType2>
              <BoxType2>
                <Date>2022.06</Date>
                <SAchieve>KSC 스타트업 선정</SAchieve>
              </BoxType2>
              <BoxType2>
                <Date>2022.05</Date>
                <SAchieve>구글 창구 엑셀러레이팅 선정</SAchieve>
              </BoxType2>
              <BoxType2 className="point">
                <BAchieve>
                  팬들 서비스 글로벌 7개국 이용
                  <Year2021Box>
                    <Year src={Y2021} />
                    <Dot />
                  </Year2021Box>
                  <FlagsWrapper>
                    <div>
                      {Global7.map((el) => (
                        <img key={el} src={el} alt="flags" />
                      ))}
                    </div>
                  </FlagsWrapper>
                </BAchieve>
              </BoxType2>
              <BoxType2>
                <Date>2021.11</Date>
                <SAchieve>500global japan 선정</SAchieve>
              </BoxType2>
              <BoxType2>
                <Date>2021.08</Date>
                <SAchieve>Ver 3.0 업데이트</SAchieve>
              </BoxType2>
              <BoxType2>
                <Date>2021.07</Date>
                <SAchieve>LAUNCHPAD 글로벌 엑셀러레이터 선정</SAchieve>
              </BoxType2>
              <BoxType2>
                <Date>2021.05</Date>
                <SAchieve>브릿지 투자 유치</SAchieve>
              </BoxType2>
              <BoxType2>
                <Date>2021.01</Date>
                <SAchieve>시드 투자 유치</SAchieve>
              </BoxType2>
              <BoxType2 className="point">
                <Date>2020.11</Date>
                <SAchieve>Ver 2.0 업데이트</SAchieve>
                <Year2020Box>
                  <Year src={Y2020} />
                  <Dot />
                </Year2020Box>
              </BoxType2>
              <BoxType2>
                <Date>2020.05</Date>
                <SAchieve>팬들 앱 정식 서비스 런칭</SAchieve>
              </BoxType2>
              <BoxType2 className="point">
                <Date>2019.09</Date>
                <SAchieve>팬들 앱 베타 서비스 런칭</SAchieve>
                <Year2019Box>
                  <Year src={Y2019} />
                  <Dot />
                </Year2019Box>
              </BoxType2>
              <BoxType2>
                <Date>2019.01</Date>
                <SAchieve>(주)패니지먼트 창립</SAchieve>
              </BoxType2>
            </Sec4Right>
          </Sec4>
          <Sec5>
            <Sec5Header>
              <OurBlah>News</OurBlah>
              <Title className="forSec5">언론에서 이야기하는</Title>
              <svg viewBox="0 0 299 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.5601 58.8881H18.4819V27.6314H25.434V16.9621H18.4819V16.5864C18.4819 12.1534 20.2199 10.6507 25.2073 10.9512V0.0564427C12.3611 -0.619784 5.5601 4.79003 5.5601 15.7599V16.9621H0.875V27.6314H5.5601V58.8881Z"
                  fill="#FF696B"
                />
                <path
                  d="M28.9366 37.85C28.9366 51.074 37.1733 59.4892 47.4503 59.4892C53.7223 59.4892 58.1807 56.634 60.5233 52.9524V58.8881H73.4451V16.9621H60.5233V22.8979C58.2563 19.2162 53.7979 16.361 47.5259 16.361C37.1733 16.361 28.9366 24.626 28.9366 37.85ZM60.5233 37.9251C60.5233 44.6122 56.216 48.2939 51.3042 48.2939C46.468 48.2939 42.0851 44.5371 42.0851 37.85C42.0851 31.1628 46.468 27.5563 51.3042 27.5563C56.216 27.5563 60.5233 31.238 60.5233 37.9251Z"
                  fill="#FF696B"
                />
                <path
                  d="M111.886 58.8881H124.733V34.3937C124.733 23.1233 118.234 16.5113 108.486 16.5113C102.818 16.5113 98.2845 18.9908 95.7153 22.5222V16.9621H82.7934V58.8881H95.7153V36.1218C95.7153 30.4115 98.889 27.2558 103.801 27.2558C108.713 27.2558 111.886 30.4115 111.886 36.1218V58.8881Z"
                  fill="#FF696B"
                />
                <path
                  d="M131.143 37.85C131.143 51.074 139.38 59.4892 149.732 59.4892C155.928 59.4892 160.387 56.7092 162.729 52.8772V58.8881H175.651V3.28731H162.729V22.7476C160.16 18.9156 155.324 16.361 149.732 16.361C139.38 16.361 131.143 24.626 131.143 37.85ZM162.729 37.9251C162.729 44.6122 158.422 48.2939 153.51 48.2939C148.674 48.2939 144.291 44.5371 144.291 37.85C144.291 31.1628 148.674 27.5563 153.51 27.5563C158.422 27.5563 162.729 31.238 162.729 37.9251Z"
                  fill="#FF696B"
                />
                <path
                  d="M182.43 37.85C182.43 51.074 190.667 59.4892 201.02 59.4892C207.216 59.4892 211.674 56.7092 214.017 52.8772V58.8881H226.939V3.28731H214.017V22.7476C211.448 18.9156 206.612 16.361 201.02 16.361C190.667 16.361 182.43 24.626 182.43 37.85ZM214.017 37.9251C214.017 44.6122 209.71 48.2939 204.798 48.2939C199.962 48.2939 195.579 44.5371 195.579 37.85C195.579 31.1628 199.962 27.5563 204.798 27.5563C209.71 27.5563 214.017 31.238 214.017 37.9251Z"
                  fill="#FF696B"
                />
                <path d="M236.287 58.8881H249.209V3.28731H236.287V58.8881Z" fill="#FF696B" />
                <path
                  d="M277.163 26.6547C281.47 26.6547 285.097 29.2844 285.097 33.8678H268.926C269.682 29.2093 272.931 26.6547 277.163 26.6547ZM297.339 44.9879H283.586C282.528 47.242 280.488 49.0453 276.86 49.0453C272.704 49.0453 269.304 46.4906 268.85 41.0057H298.094C298.246 39.7284 298.321 38.4511 298.321 37.2489C298.321 24.4757 289.631 16.361 277.314 16.361C264.77 16.361 256.004 24.626 256.004 37.9251C256.004 51.2242 264.921 59.4892 277.314 59.4892C287.817 59.4892 295.298 53.1778 297.339 44.9879Z"
                  fill="#FF696B"
                />
              </svg>
            </Sec5Header>
            <Sec5Body>
              {fanddleNews.map((el) => (
                <ArticleWrapper key={el.id}>
                  <Article>{el.summary}</Article>
                  <ArticleDate>{el.date}</ArticleDate>
                  <Published>{el.publish}</Published>
                </ArticleWrapper>
              ))}
            </Sec5Body>
          </Sec5>
        </InnerWrapper>
      </Wrapper>
    </>
  );
}
const Global17: string[] = [
  US,
  Canada,
  Austrailia,
  Dubai,
  British,
  Germany,
  France,
  Spain,
  Mexico,
  Japan,
  Taiwan,
  Hongkong,
  China,
  Singapore,
  Malaysia,
  Indonesia,
  India,
];
const Global7: string[] = [US, France, Japan, Taiwan, Hongkong, Singapore, Malaysia];
interface IFanddleNews {
  id: number;
  summary: string;
  date: string;
  publish: string;
}
const fanddleNews: IFanddleNews[] = [
  {
    id: 1,
    summary: `K-Pop fandom app "Fanddle" gives Kpop fans an opportunity to send gifts to celebrities`,
    date: "2021.10.12",
    publish: "코리아넷",
  },
  {
    id: 2,
    summary: `팬커머스 플랫폼 ‘팬들’ 운영사 패니지먼트, 신용보증기금과 골드아크로부터 시드투자유치`,
    date: "2021.08.02",
    publish: "Platum",
  },
  {
    id: 3,
    summary: `문화(Culture)를 사람들과 함께 하며 산업화로 이끄는 스타트업`,
    date: "2020.11.25",
    publish: "한경경제",
  },
];

const UpAndDown = keyframes`
  0% {
    transform : translateY(0);
  }
  100% {
    transform : translateY(-20px)
  }
`;

const Main = styled.div`
  height: 108rem;
  margin-top: -80px;
  font-family: "Pretendard";
  font-style: normal;
  background-image: linear-gradient(
      360deg,
      #121212 3.73%,
      rgba(18, 18, 18, 0.603825) 19.28%,
      rgba(18, 18, 18, 0) 115.77%
    ),
    url(${img1});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  color: #ffffff;
  position: relative;
  p {
    margin-top: 59.3rem;
    width: 92rem;
    height: 18rem;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 6rem;
    line-height: 9rem;
    text-align: center;
    color: #ffffff;
  }
  svg {
    position: absolute;
    bottom: 52.5px;
    animation: ${UpAndDown} 0.8s infinite alternate ease-in-out;
  }
`;
const Wrapper = styled.div`
  background: black;
  color: #ffffff;
  font-family: "Pretendard";
  font-style: normal;
`;
const InnerWrapper = styled.div`
  max-width: 140.4rem;
  background: black;
  margin: 0 auto;
  padding-top: 27.2rem;
`;
const Sec1 = styled.div`
  p {
    width: 78.3rem;
    height: 12rem;
    font-weight: 400;
    font-size: 4rem;
    line-height: 6rem;
  }
`;
const Sec1ImgWrapper = styled.div`
  display: flex;
  align-items: baseline;
  height: 60rem;
  & > img {
    width: 32rem;
    height: 48rem;
    margin-right: 52rem;
    &:last-child {
      display: block;
      position: relative;
      bottom: -120px;
      width: 36rem;
      height: 24rem;
    }
  }
`;
const Sec1InWrap = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12rem;
  padding-bottom: 6.2rem;
  img {
    display: block;
    width: 48rem;
    height: 72rem;
  }
`;
const SloganWrapper = styled.div`
  p {
    font-family: "Montserrat";
    margin-bottom: 8rem;
    font-style: normal;
    font-weight: 700;
    font-size: 140px;
    line-height: 100%;
    color: #ffffff;
    opacity: 0.2;
    margin-right: 9.5rem;
  }
`;
const Sec2 = styled.div`
  padding-top: 34.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    &:last-child {
      width: 74rem;
      height: 160px;
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      color: #888888;
    }
  }
`;
const Sec3 = styled.div`
  margin-top: 32rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 36rem;
`;
const Title = styled.h1`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 90px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 8rem;
`;
const OurBlah = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  color: #888888;
  margin-bottom: 4rem;
`;
const ServiceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
  img {
    width: 40px;
    height: 40px;
  }
`;
const Circle = styled.div`
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
  border: 4px solid #666666;
  border-radius: 50%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
`;
const ServiceDetailWrapper = styled.div`
  margin-top: 12rem;
  & > div {
    display: flex;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 6rem;
    border-top: 1px solid #888888;
    &:last-child {
      border-bottom: 1px solid #888888;
    }
    p:first-child {
      position: relative;
      top: 5px;
      width: 31rem;
      height: 8rem;
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 48px;
      margin-right: 20.6rem;
      color: #ececec;
    }
    p:last-child {
      width: 692px;
      height: 80px;
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 40px;
      color: #888888;
    }
  }
`;

const Sec4 = styled.div`
  display: flex;
  justify-content: center;
  gap: 22rem;
  padding-bottom: 22.8rem;
`;
const Sec4Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  top: -7rem;
  h1 {
    width: 490px;
    height: 360px;
    line-height: 90px;
    text-align: start;
  }
`;
const Sec4Right = styled.div`
  .point {
    margin-top: 1.5rem;
    position: relative;
    &:first-child {
      top: -5px;
      margin-top: 0;
    }
  }
  padding-left: 4.6rem;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  height: 138rem;
`;

const Date = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #666666;
`;

const SAchieve = styled.p`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: #aaaaaa;
`;
const BAchieve = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  font-family: "Pretendard";
  display: flex;
  flex-direction: column;
  font-style: normal;
`;
const BoxType1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 20px 26px;
  gap: 20px;
  width: 450px;
  height: 208px;
  margin-bottom: 2rem;
`;
const BoxType2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 20px 26px;
  gap: 10px;
  width: 450px;
  height: 68px;
  margin-bottom: 2rem;
`;
const FlagsWrapper = styled.div`
  height: 32px;
  margin-top: 1rem;
  margin-bottom: 3.4rem;
  padding-left: 1rem;
  img {
    width: 32px;
    margin-right: 10px;
  }
`;
const Year2022Box = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  left: -170.5px;
  top: 2.3px;
`;
const Dot = styled.div`
  width: 10px;
  height: 10px;
  background: #ff696b;
  border-radius: 50%;
  margin-left: 23.5px;
  display: block;
  transform: translate(0.5px, -1px);
`;
const Year = styled.img``;
const Year2021Box = styled(Year2022Box)`
  left: -137px;
`;
const Year2020Box = styled(Year2022Box)`
  left: -146px;
  top: 0px;
`;
const Year2019Box = styled(Year2022Box)`
  left: -139px;
`;
const Sec5 = styled.div`
  svg {
    width: 299px;
    height: 60px;
  }
  padding-bottom: 32rem;
`;
const Sec5Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .forSec5 {
    margin-bottom: 1.3rem;
  }
`;
const Sec5Body = styled.div`
  display: flex;
  margin-top: 105.51px;
  justify-content: space-between;
`;
const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Article = styled.p`
  width: 377px;
  height: 84px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #aaaaaa;
  border-bottom: 1px solid #666666;
  margin-bottom: 12px;
`;
const ArticleDate = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #666666;
  margin-bottom: 10px;
`;
const Published = styled.span`
  width: 56px;
  height: 16px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #aaaaaa;
`;

export default Aboutus;
