import styled from "styled-components";
import guideMsg from "../../images/logos/안내메시지.png";
import cuteImg from "../../images/logos/cute1.png";
import FanddleLogoForLogin from "../fanddleLogoForLogin.components";
import { userInfo } from "../../pages/signUp.pages";
import { useRecoilValue } from "recoil";
import { userInfoState } from "../../atoms";

function SignUp2() {
  const userInfo = useRecoilValue(userInfoState);
  console.log(userInfo);
  return (
    <Wrapper>
      <FanddleLogoForLogin />
      <Guide>아래 이메일로 인증메일을 보냈습니다.</Guide>
      <UserEmail>{userInfo.email}</UserEmail>
      <MsgImg src={guideMsg} />
      <CuteImg src={cuteImg} />
      <BtnWrapper>
        <button>인증메일 재전송</button>
      </BtnWrapper>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 470px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 16px;
  padding: 50px 40px 40px 40px;
`;
const Guide = styled.p`
  font-family: "Pretendard";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  margin-bottom: 10px;
`;
const UserEmail = styled.p`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #ff696b;
  margin-bottom: 32px;
`;
const MsgImg = styled.img`
  display: block;
  width: 200px;
  height: 40px;
  margin-bottom: 60px;
`;
const CuteImg = styled.img`
  display: block;
  width: 260px;
  height: 228px;
  margin: 0 auto 80px auto;
`;
const BtnWrapper = styled.div`
  button {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    font-family: "Pretendard";
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    &:first-child {
      background: #fff0f0;
      color: #ff696b;
    }
  }
`;
export default SignUp2;
