import styled from "styled-components";
import Instagram from "../images/logos/instagram.png";
import Youtube from "../images/logos/youtube.png";
import Twitter from "../images/logos/twitter.png";
import Kakao from "../images/logos/kakao.png";
import Line from "../images/logos/line.png";

interface ISNS {
  name: string;
  image: string;
  to: string;
}
const SNS: ISNS[] = [
  { name: "instagram", image: Instagram, to: "https://instagram.com/fanddle_official?igshid=MDE2OWE1N2Q=" },
  { name: "youtube", image: Youtube, to: "https://youtube.com/c/Fanddle" },
  { name: "twitter", image: Twitter, to: "https://twitter.com/FanddleOFFicial" },
  { name: "kakao", image: Kakao, to: "https://pf.kakao.com/_QrxaAj" },
  { name: "line", image: Line, to: "https://line.me/ti/p/xqTDEblnLU" },
];

function Footer() {
  return (
    <Wrapper>
      <InnerWrapper>
        <ContactUs>
          <strong>고객센터</strong>
          <TextWrapper>
            <p>전화 : 02-6101-7858 (평일 오전 10시 - 오후 6시)</p>
            <p>이메일 : info@fanddle.com</p>
          </TextWrapper>
        </ContactUs>
        <Fanagement>
          <strong>(주)패니지먼트</strong>
          <TextWrapper>
            <p>사업자 등록번호 : 647-87-01172 | 대표 : 박시하</p>
            <p>통신판매업 신고번호 : 제2021-서울성동-02511호 </p>
            <p>주소 : 서울특별시 영등포구 국회대로 70길 19, 10F (대하빌딩)</p>
          </TextWrapper>
        </Fanagement>
        <SocialMedias>
          <strong>소셜 미디어</strong>
          <SNSWrapper>
            {SNS.map((el) => (
              <div key={el.name}>
                <a href={el.to}>
                  <img src={el.image} alt="social network service logos" />
                </a>
              </div>
            ))}
          </SNSWrapper>
        </SocialMedias>
      </InnerWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.footer`
  width: 100%;
  background: rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(10px);
  height: 200px;
  position: absolute;
  bottom: 0;
`;
const InnerWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  gap: 131px;
  padding-top: 48px;
  strong {
    color: #cecece;
    font-size: 20px;
    font-weight: 600;
    display: block;
    margin-bottom: 20px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    color: #aaaaaa;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;
const ContactUs = styled.div``;
const Fanagement = styled.div``;
const SocialMedias = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const SNSWrapper = styled.div`
  display: flex;
  gap: 10px;
  img {
    width: 36px;
  }
`;
export default Footer;
