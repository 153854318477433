import styled from "styled-components";
import statusFollowing from "../images/mypageIcons/Status_following.png";
import statusGift from "../images/mypageIcons/Status_gift.png";
import statusWishlist from "../images/mypageIcons/Status_wishlist.png";
import statusCoin from "../images/mypageIcons/Status_coin.png";
import arrow from "../images/mypageIcons/arrow.png";
import Product from "../components/product.components";
import Sidebar from "../components/sidebar.components";
import EmptyStatus from "../components/myPageComponents/mypageEmptyStatus.components";
import Status from "../components/myPageComponents/mypageStatus.components";
import RecentOrderBox from "../components/recentOrderBox.components";
import CelebCard from "../components/celebCard.components";
import MypageHeader from "../components/myPageComponents/mypageHeader.componenets";
import useFetchiungMypageData from "../hooks/useFetchingMypageData.hooks";
import { Helmet } from "react-helmet-async";

function MyPage() {
  const data = useFetchiungMypageData();
  const StatusArr = [
    { imgUrl: statusFollowing, statusTitle: "팔로우중인 셀럽", number: data.followingTotal, arrow, linkTo: "/" },
    { imgUrl: statusWishlist, statusTitle: "위시리스트", number: data.wishlistTotal, arrow, linkTo: "/" },
    { imgUrl: statusGift, statusTitle: "주고 받은 선물", number: data.giftTotal, arrow, linkTo: "/" },
    {
      imgUrl: statusCoin,
      statusTitle: "보유한 토리",
      number: Math.floor(data.coin).toLocaleString("ko-KR"),
      arrow,
      linkTo: "/",
    },
  ];
  return (
    <>
      <Helmet>
        <title>팬들 - 마이페이지</title>
      </Helmet>
      <Wrapper>
        <Sidebar />
        <MainSection>
          <StatusBox>
            {StatusArr.map((el: any, idx: any) => (
              <Status
                key={idx}
                imgUrl={el.imgUrl}
                statusTitle={el.statusTitle}
                number={el.number}
                arrow={el.arrow}
                linkTo={el.linkTo}
              />
            ))}
          </StatusBox>
          <FollowingWrapper>
            <MypageHeader Title="팔로우중인 셀럽" linkTo="/" />
            {data.followingTotal === 0 ? (
              <EmptyStatus
                EmptyText="팔로우중인 셀럽이 없습니다."
                BtnTitle="셀럽 찾기"
                arrow={arrow}
                boxHeight="206px"
                linkTo="/"
              />
            ) : (
              <CWrapper>
                {data?.followings.map((el: any) => (
                  <CelebCard key={el.uid} imgUrl={el.following.thumbnail} celebName={el.following.enName} />
                ))}
              </CWrapper>
            )}
          </FollowingWrapper>
          <OrderWrapper>
            <MypageHeader Title="최근 보낸 선물" linkTo="/" />
            {data.giftsendTotal === 0 ? (
              <EmptyStatus
                EmptyText="주문 내역이 없습니다."
                BtnTitle="선물하러 가기"
                arrow={arrow}
                boxHeight="264px"
                linkTo="/"
              />
            ) : (
              data?.giftsend
                .slice(0, 2)
                .map((el: any, idx: number) => (
                  <RecentOrderBox
                    key={el.uid}
                    group={el.gifts[0].items[0]}
                    orderedAt={el.orderedAt}
                    orderNo={el.orderNo}
                    recvName={el.gifts[0].recvName}
                    isLast={idx === 1 ? true : false}
                  />
                ))
            )}
          </OrderWrapper>
          <ItemWrapper>
            <MypageHeader Title="위시리스트" linkTo="/" />
            {data.wishlistTotal === 0 ? (
              <EmptyStatus
                EmptyText="해당 내역이 없습니다."
                BtnTitle="선물하러 가기"
                arrow={arrow}
                boxHeight="294px"
                linkTo="/"
              />
            ) : (
              <WishWrapper>
                {data?.wishlist.slice(0, 4).map((el: any) => (
                  <Product
                    key={el.uid}
                    imgSrc={el.item.thumbnail}
                    brandName={el.item.brand.name}
                    itemName={el.item.name}
                    price={el.item.price}
                  />
                ))}
              </WishWrapper>
            )}
          </ItemWrapper>
        </MainSection>
      </Wrapper>
    </>
  );
}
export default MyPage;
/* layout */
const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  gap: 40px;
`;
const MainSection = styled.div`
  max-width: 800px;
  width: calc(100% - 280px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
/* Status Box */
const StatusBox = styled.div`
  display: flex;
  padding: 12px 0px;
  height: 148px;
  border: 1px solid #ececec;
  border-radius: 10px;
  margin-bottom: 32px;
`;
/* Following Box */
const FollowingWrapper = styled.div`
  margin-bottom: 80px;
`;
const CWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 800px;
`;
/* Recent Order Box */
const OrderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 300px;
  margin-bottom: 80px;
`;
/* Wishlist */
const ItemWrapper = styled.div`
  margin-bottom: 96px;
`;
const WishWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 22px;
`;
