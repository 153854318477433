import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { ReactComponent as CloseIcon } from "../images/logos/icons/close.svg";
import { ReactComponent as ArrowIcon } from "../images/logos/icons/arrow.svg";
import camera from "../images/logos/icons/cameraInCircle.png";
import Sidebar from "../components/sidebar.components";
import { Helmet } from "react-helmet-async";

function Profile() {
  const [profile, setProfile] = useState({
    name: "",
    username: "",
    email: "",
    country: "",
    countryUid: "",
    thumbnail: "",
  });

  const [fan, setFan] = useState(""); //type: 1 팬, type: 2 셀럽, type: 3 그룹

  const [gender, setGender] = useState(0); // 남성 1, 여성 2, 밝히지 않음 0

  const [username, setUsername] = useState("");

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleTextDelete = (event: any) => {
    setUsername("");
  };

  const handleGender = (num: number) => {
    setGender(num);
  };

  const fileRef = useRef<HTMLInputElement>(null);

  const handleImageClick = () => {
    fileRef && fileRef.current?.click();
  };

  const [encodedImg, setEncodedImg] = useState("");
  const [imgFile, setImgFile] = useState<any>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setImgFile(file);

    //이미지 인코딩
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onload = (event) => {
      const result = event?.target?.result as string;
      setEncodedImg(result);
    };
  };

  const getProfileInfo = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/my/profile`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TEST_TOKEN}`,
        },
      })
      .then((res) => {
        const data = res.data.data.profile;
        const profileData = {
          name: data.name,
          username: data.username,
          email: data.account.email,
          country: data.countries.name,
          countryUid: data.countries.uid,
          thumbnail: data.thumbnail,
        };
        setProfile(profileData);

        const usernameData = data.username;
        setUsername(usernameData);

        if (data.type === 1) {
          setFan("팬");
        } else if (data.type === 2) {
          setFan("셀럽");
        } else if (data.type === 3) {
          setFan("그룹");
        }

        if (data.gender === 0) {
          setGender(0);
        } else if (data.gender === 1) {
          setGender(1);
        } else if (data.gender === 2) {
          setGender(2);
        }
      });
  };

  //회원정보 가져오기
  useEffect(() => {
    getProfileInfo();
  }, []);

  const getImgUrl = async () => {
    if (imgFile) {
      const imgUpload = new FormData();
      imgUpload.append("image", imgFile);

      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/upload/image`, imgUpload, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TEST_TOKEN}`,
        },
      });
      return res.data.data.image;
    } else {
      return profile.thumbnail;
    }
  };

  //저장 버튼 클릭 후 변경사항 데이터베이스 업데이트
  const handleSave = async () => {
    const imgUrl = await getImgUrl();
    await axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/my/profile`,
        {
          thumbnail: imgUrl || profile.thumbnail,
          email: profile.email,
          name: profile.name,
          username: username,
          countryUid: profile.countryUid,
          gender: gender,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TEST_TOKEN}`,
          },
        },
      )
      .then((res) => {
        alert("저장완료");
      });
  };

  return (
    <>
      <Helmet>
        <title>팬들 - 프로필</title>
      </Helmet>
      <Wrapper>
        <Sidebar />
        <MainSection>
          <Header>
            <Photo>
              <img src={encodedImg || profile.thumbnail} alt="thumbnail" />
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                name="profile_img"
                onChange={handleImageChange}
                ref={fileRef}
              />
              <Camera>
                <img src={camera} alt="camera" onClick={handleImageClick} />
              </Camera>
            </Photo>
            <ProfileInfo>
              <Name>{profile.name}</Name>
              <Email>{profile.email}</Email>
              <Status>
                <span>{fan}</span> ∙ <span>{profile.country}</span>
              </Status>
            </ProfileInfo>
          </Header>
          <Main>
            <InfoBox>
              <Title>
                <label htmlFor="email">이메일</label>
              </Title>
              <Content>
                <input id="email" type="email" value={profile.email} disabled />
              </Content>
            </InfoBox>
            <InfoBox>
              <Title>
                <label htmlFor="name">이름</label>
              </Title>
              <Content>
                <input id="name" type="text" value={profile.name} disabled />
              </Content>
            </InfoBox>
            <InfoBox>
              <Title>
                <label htmlFor="username">사용자 이름</label>
              </Title>
              <Content>
                <input id="username" type="text" value={username} onChange={handleTextChange} />
                <CloseIcon onClick={handleTextDelete} />
              </Content>
            </InfoBox>
            <InfoBox>
              <Title>
                <label htmlFor="country">국가</label>
              </Title>
              <Content>
                <div id="country">국가를 선택해 주세요.</div>
                <ArrowIcon />
              </Content>
            </InfoBox>
            <Gender>
              <Title>성별</Title>
              <div className="buttons">
                <GenderBtn isActive={gender === 1} onClick={() => handleGender(1)}>
                  남성
                </GenderBtn>
                <GenderBtn isActive={gender === 2} onClick={() => handleGender(2)}>
                  여성
                </GenderBtn>
                <GenderBtn isActive={gender === 0} onClick={() => handleGender(0)}>
                  밝히지 않음
                </GenderBtn>
              </div>
            </Gender>
            <SaveBtn onClick={handleSave}>저장</SaveBtn>
          </Main>
        </MainSection>
      </Wrapper>
    </>
  );
}

export default Profile;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  gap: 122px;
  font-family: "Pretendard";
  font-style: normal;
`;

const MainSection = styled.div`
  padding: 60px 50px;
  gap: 40px;
  width: 690px;
  height: 676px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
`;

const Photo = styled.div`
  position: relative;
  margin-right: 40px;
  width: 80px;
  height: 81px;
  left: 0px;
  top: 0px;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 70%;
  }
`;

const Camera = styled.div`
  position: absolute;
  left: 48px;
  top: 48px;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const ProfileInfo = styled.div`
  padding-bottom: 40px;
  height: 81px;
  width: 470px;
`;

const Name = styled.div`
  height: 36px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 2px;
`;
const Email = styled.div`
  height: 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #aaaaaa;
  margin-bottom: 8px;
`;

const Status = styled.div`
  display: flex;
  height: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #aaaaaa;
`;

const Main = styled.main``;

const InfoBox = styled.div`
  display: flex;
  padding-bottom: 40px;
`;

const Title = styled.label`
  display: inline-block;
  align-items: center;
  width: 80px;
  height: 40px;
  margin-right: 40px;
  padding: 12px 0 12px;
  font-weight: 500;
  font-size: 16px;
  text-align: right;
  line-height: 16px;
  text-align: right;
  color: #333333;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const Content = styled.div`
  border-bottom: 1px solid #aaaaaa;
  display: flex;
  justify-content: space-between;
  width: 470px;
  height: 40px;
  padding-left: 10px;
  align-items: center;
  font-weight: 500;
  font-size: 16px;

  input {
    background-color: transparent;
    width: 420px;
    border-style: none;
    line-height: 35px;
    color: #333333;
    cursor: default;
  }
  div {
    width: 420px;
    color: #aaaaaa;
    width: 420px;
    display: flex;
    align-items: center;
  }
`;

const Gender = styled.div`
  display: flex;
  padding-bottom: 40px;

  .buttons {
    display: flex;
    gap: 10px;

    button:last-child {
      width: 100px;
    }
  }
`;

const GenderBtn = styled.button<{ isActive: boolean }>`
  width: 175px;
  height: 38px;
  color: ${(props) => (props.isActive ? "#FF696B" : "#aaa")};
  background: ${(props) => (props.isActive ? "#FFF0F0" : "#ECECEC")};
  border-radius: 4px;
  border: 0;
  outline: 0;
`;

const SaveBtn = styled.button`
  border: 0;
  outline: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 10px;
  width: 134px;
  height: 38px;
  color: #ffffff;
  background: #ff696b;
  border-radius: 4px;
  float: right;
`;
