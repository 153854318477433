import { atom } from "recoil";

export const loginState = atom<boolean>({
  key: "loginState",
  default: false,
});

export const userInfoState = atom<IUserInfo>({
  key: "userInfoState",
  default: {
    email: "",
    id: "",
    accountType: 0,
    emailToken: "",
    name: "",
    username: "",
    termsUse: 1,
    termsPrivacy: 1,
    countryUid: "",
    follow: [],
  },
});

interface IUserInfo {
  email: string;
  id: string;
  accountType: number;
  emailToken: string;
  name: string;
  username: string;
  termsUse: number;
  termsPrivacy: number;
  countryUid: string;
  follow: string[];
}
