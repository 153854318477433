import styled, { css } from "styled-components";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Mousewheel, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Section1 from "../components/homeComponents/section1.homeComponents";
import Section2 from "../components/homeComponents/section2.homeComponents";
import Section3 from "../components/homeComponents/section3.homeComponents";
import Section4 from "../components/homeComponents/section4.homeComponents";
import Section5 from "../components/homeComponents/section5.homeComponents";
import "swiper/css";
import "swiper/css/pagination";

function Aboutfanddle() {
  const [idx, setIdx] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
  const [slideStart, setSlideStart] = useState(true);
  const [maxLength, setMaxLength] = useState(0);

  const slideByArrow = (idx: number) => {
    if (!slideStart) return;
    else {
      if (idx === maxLength) {
        swiperInstance?.slideTo(0, 1050);
      } else {
        swiperInstance?.slideNext(700);
      }
    }
  };

  useEffect(() => {
    const swipeBySpace = (e: KeyboardEvent) => {
      if (slideStart && e.code === "Space") swiperInstance?.slideNext(700);
    };

    const startWatchKey = () => {
      window.addEventListener("keyup", swipeBySpace);
    };

    startWatchKey();

    return () => window.removeEventListener("keyup", swipeBySpace);
  }, [idx, swiperInstance, slideStart]);

  return (
    <>
      <Helmet>
        <title>팬들 - fanddle이란?</title>
      </Helmet>
      <Wrapper>
        <Swiper
          direction={"vertical"}
          slidesPerView={1}
          mousewheel={true}
          modules={[Mousewheel, Pagination, Autoplay]}
          speed={700}
          pagination={{
            type: "progressbar",
          }}
          onSwiper={(swiper) => {
            setMaxLength(swiper.slides.length - 1);
            setSwiperInstance(swiper);
          }}
          // autoplay={{ delay: idx === 0 || idx === maxLength ? 4000 : 2000, disableOnInteraction: false }}
          onRealIndexChange={(swiper) => setIdx(swiper.realIndex)}
          onSlideChangeTransitionStart={(swiper) => setSlideStart(false)}
          onSlideChangeTransitionEnd={(swiper) => setSlideStart(true)}
          threshold={60}>
          <SwiperSlide>
            <Section1 idx={idx} />
          </SwiperSlide>
          <SwiperSlide>
            <Section2 idx={idx} />
          </SwiperSlide>
          <SwiperSlide>
            <Section3 idx={idx} />
          </SwiperSlide>
          <SwiperSlide>
            <Section4 idx={idx} />
          </SwiperSlide>
          <SwiperSlide>
            <Section5 idx={idx} />
          </SwiperSlide>
        </Swiper>
        <ArrowWrapper idx={idx} maxLength={maxLength} onClick={() => slideByArrow(idx)}>
          <Arrow viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" idx={idx} maxLength={maxLength}>
            <rect />
            <path d="M26.4854 15.2427L25.0711 16.6569L18.0001 10.0827L10.929 16.6569L9.51479 15.2427L18.0001 6.75739L26.4854 15.2427Z" />
          </Arrow>
        </ArrowWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  /* width: 100%; */
  position: relative;
  background: #121212;
`;

const ArrowWrapper = styled.div<{ idx: number; maxLength: number }>`
  position: absolute;
  width: 32px;
  height: ${(props) => (props.idx !== 0 && props.idx === props.maxLength ? "32px" : "48px")};
  border-radius: ${(props) => (props.idx !== 0 && props.idx === props.maxLength ? "50%" : "16px")};
  z-index: 3;
  bottom: ${(props) => (props.idx !== 0 && props.idx === props.maxLength ? "30%" : "7%")};
  left: 3.15%;
  border: 2px solid black;
  transition-property: height, border-radius, bottom;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid white;
  &:hover {
    background: white;
  }
`;
const Arrow = styled.svg<{ idx: number; maxLength: number }>`
  width: 36px;
  height: 36px;
  transform: rotateZ(-180deg);
  fill: white;
  &:hover {
    fill: black;
  }
  ${(props) =>
    props.idx !== 0 &&
    props.idx === props.maxLength &&
    css`
      transform: rotateZ(0deg);
    `}
  transition : transform .7s ease-in-out;
  rect {
    width: 2px;
    height: 20px;
    x: 17;
    y: 9.24268;
  }
`;
export default Aboutfanddle;
