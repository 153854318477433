import styled from "styled-components";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { throttle } from "../utils/throttle.utils";
import { useRecoilState } from "recoil";
import { loginState } from "../atoms";
import LoginModal from "./loginModal.components";
import { useMatch } from "react-router-dom";
import SelectLanguage from "./selectLanguage.components";

export interface ICategory {
  value: string;
  to?: string;
  redirect?: boolean;
}
const headerMenus: ICategory[] = [
  { value: "회사소개", to: "aboutus" },
  { value: "입점문의", to: "", redirect: true },
];

function Header() {
  const [input, setInput] = useState("");
  // const [hide, setHide] = useState(false);
  const [pageY, setPageY] = useState(0);
  const [loginModalOn, setLoginModalOn] = useRecoilState(loginState);
  const documentRef = useRef(document);
  const navigate = useNavigate();
  const curLoc = useLocation().pathname;
  const isAboutUsPage: any = useMatch("/aboutus");
  const actived = (text: string): boolean => {
    return curLoc.includes(text);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    setInput(value);
  };
  const goHome = (): void => {
    navigate("/");
  };

  // useEffect(() => {
  //   const current = documentRef.current;
  //   const handleScroll = () => {
  //     const { pageYOffset } = window;
  //     const deltaY = pageYOffset - pageY;
  //     const hide = pageYOffset > 80 && deltaY >= 0;
  //     setHide(hide);
  //     setPageY(pageYOffset);
  //   };
  //   const throttled = throttle(handleScroll, 200);
  //   current.addEventListener("scroll", throttled);
  //   return () => current.removeEventListener("scroll", throttled);
  // }, [pageY]);

  return (
    <Wrapper dark={isAboutUsPage}>
      <MainHeader>
        <InnerWrapper>
          <NavLeft>
            <svg
              width="107"
              onClick={goHome}
              height="22"
              viewBox="0 0 107 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.99907 21.2713H6.59349V10.1578H9.06534V6.36428H6.59349V6.2307C6.59349 4.65451 7.21145 4.12021 8.98474 4.22707V0.353382C4.41718 0.112945 1.99907 2.03643 1.99907 5.93684V6.36428H0.333252V10.1578H1.99907V21.2713Z"
                fill="#FF696B"
              />
              <path
                d="M10.3107 13.7911C10.3107 18.4929 13.2393 21.485 16.8934 21.485C19.1234 21.485 20.7086 20.4699 21.5415 19.1608V21.2713H26.1359V6.36428H21.5415V8.47477C20.7355 7.16573 19.1503 6.15056 16.9202 6.15056C13.2393 6.15056 10.3107 9.08922 10.3107 13.7911ZM21.5415 13.8178C21.5415 16.1954 20.0101 17.5045 18.2636 17.5045C16.5441 17.5045 14.9857 16.1687 14.9857 13.7911C14.9857 11.4134 16.5441 10.1311 18.2636 10.1311C20.0101 10.1311 21.5415 11.4401 21.5415 13.8178Z"
                fill="#FF696B"
              />
              <path
                d="M39.804 21.2713H44.3715V12.5622C44.3715 8.55492 42.0609 6.20399 38.5949 6.20399C36.5798 6.20399 34.9677 7.08559 34.0542 8.3412V6.36428H29.4598V21.2713H34.0542V13.1766C34.0542 11.1463 35.1827 10.0242 36.9291 10.0242C38.6755 10.0242 39.804 11.1463 39.804 13.1766V21.2713Z"
                fill="#FF696B"
              />
              <path
                d="M46.6507 13.7911C46.6507 18.4929 49.5793 21.485 53.2602 21.485C55.4634 21.485 57.0486 20.4966 57.8815 19.1341V21.2713H62.4759V1.50213H57.8815V8.42134C56.968 7.05887 55.2484 6.15056 53.2602 6.15056C49.5793 6.15056 46.6507 9.08922 46.6507 13.7911ZM57.8815 13.8178C57.8815 16.1954 56.35 17.5045 54.6036 17.5045C52.8841 17.5045 51.3257 16.1687 51.3257 13.7911C51.3257 11.4134 52.8841 10.1311 54.6036 10.1311C56.35 10.1311 57.8815 11.4401 57.8815 13.8178Z"
                fill="#FF696B"
              />
              <path
                d="M64.8863 13.7911C64.8863 18.4929 67.8149 21.485 71.4958 21.485C73.699 21.485 75.2842 20.4966 76.1171 19.1341V21.2713H80.7115V1.50213H76.1171V8.42134C75.2036 7.05887 73.484 6.15056 71.4958 6.15056C67.8149 6.15056 64.8863 9.08922 64.8863 13.7911ZM76.1171 13.8178C76.1171 16.1954 74.5856 17.5045 72.8392 17.5045C71.1196 17.5045 69.5613 16.1687 69.5613 13.7911C69.5613 11.4134 71.1196 10.1311 72.8392 10.1311C74.5856 10.1311 76.1171 11.4401 76.1171 13.8178Z"
                fill="#FF696B"
              />
              <path d="M84.0354 21.2713H88.6298V1.50213H84.0354V21.2713Z" fill="#FF696B" />
              <path
                d="M98.5688 9.81053C100.1 9.81053 101.39 10.7456 101.39 12.3752H95.6402C95.9089 10.7188 97.0642 9.81053 98.5688 9.81053ZM105.743 16.329H100.853C100.476 17.1305 99.751 17.7716 98.4614 17.7716C96.9836 17.7716 95.7746 16.8633 95.6134 14.9131H106.011C106.065 14.459 106.092 14.0048 106.092 13.5774C106.092 9.03579 103.002 6.15056 98.6226 6.15056C94.1625 6.15056 91.0458 9.08922 91.0458 13.8178C91.0458 18.5464 94.2162 21.485 98.6226 21.485C102.357 21.485 105.017 19.241 105.743 16.329Z"
                fill="#FF696B"
              />
            </svg>
            <HeaderMenus>
              {headerMenus.map((el, idx) =>
                el.redirect ? (
                  <HeaderMenu actived={idx === 0 && actived("about")} dark={isAboutUsPage} key={el.value}>
                    <a href="https://moaform.com/q/BNuRue ">{el.value}</a>
                  </HeaderMenu>
                ) : (
                  <HeaderMenu
                    actived={idx === 0 && actived("about")}
                    dark={isAboutUsPage}
                    onClick={() => {
                      el.to && navigate(el.to);
                    }}
                    key={el.value}>
                    {el.value}
                  </HeaderMenu>
                ),
              )}
            </HeaderMenus>
          </NavLeft>
          {/* <NavRight>
            <SearchForm>
              <SearchBar name="search" onChange={onInputChange} placeholder="찾으시는 셀럽이나 상품을 검색해보세요" />
              <MagniGlass width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19 19L14.7501 14.7425M17.1053 9.05263C17.1053 11.1883 16.2569 13.2365 14.7467 14.7467C13.2365 16.2569 11.1883 17.1053 9.05263 17.1053C6.91694 17.1053 4.86872 16.2569 3.35856 14.7467C1.8484 13.2365 1 11.1883 1 9.05263C1 6.91694 1.8484 4.86872 3.35856 3.35856C4.86872 1.8484 6.91694 1 9.05263 1C11.1883 1 13.2365 1.8484 14.7467 3.35856C16.2569 4.86872 17.1053 6.91694 17.1053 9.05263Z"
                  stroke="#AAAAAA"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </MagniGlass>
            </SearchForm>
            <Login
              onClick={() => {
                setLoginModalOn(true);
              }}>
              로그인
            </Login>
          </NavRight> */}
          {/* <SelectLanguage /> */}
        </InnerWrapper>
      </MainHeader>
      {loginModalOn && <LoginModal />}
    </Wrapper>
  );
}
export default Header;

// const Wrapper = styled.div<{ hide: boolean; dark: any }>`
//   position: sticky;
//   top: 0;
//   z-index: 3;
//   transition: top 0.2s ease-in-out;
//   top: ${(props) => (props.hide ? "-90px" : "0px")};
//   background: ${(props) => (props.dark ? "rgba(0, 0, 0, 0.2);" : "rgba(255,255,255,0.92)")};
// `;
const Wrapper = styled.div<{ dark: any }>`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  /* background: ${(props) => (props.dark ? "rgba(0, 0, 0, 0.2);" : "rgba(255,255,255,0.92)")}; */
`;
const MainHeader = styled.div`
  padding: 1.2rem 0rem;
  height: 64px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  backdrop-filter: blur(30px);
`;
const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1320px;
  align-items: center;
  margin: 0 auto;
`;
const NavLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  height: 4rem;
  svg {
    margin-right: 3rem;
    cursor: pointer;
  }
`;
const NavRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 2rem;
  gap: 2rem;
  width: 52rem;
  height: 4rem;
`;
const SearchForm = styled.form`
  display: flex;
  align-items: center;
  position: relative;
`;
const SearchBar = styled.input`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 2rem;
  gap: 1rem;
  width: 36rem;
  height: 4rem;
  background: #f5f5f5;
  border-radius: 0.4rem;
  border: none;
  outline: none;
`;
const SubHeaderAbout = styled.div<{ dark: any }>`
  height: 8rem;
  background: ${(props) => (props.dark ? "rgba(0,0,0,0.2)" : "rgba(255,255,255,0.92)")};
  position: absolute;
  backdrop-filter: blur(30px);
  width: 100%;
  display: flex;
  align-items: center;
  & > div {
    max-width: 132rem;
    width: 100%;
    margin: 0 auto;
    button {
      cursor: pointer;
    }
  }
`;
const HeaderMenus = styled.ul`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  margin-top: 0.7rem;
`;
const HeaderMenu = styled.li<{ actived: boolean; dark: any }>`
  height: 2.115rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3.8rem 2rem;
  color: ${(props) => (props.actived ? "#FF696B" : props.dark ? "#e2dada" : "#e2dada")};
  font-weight: ${(props) => (props.actived ? "600" : "500")};
  &:hover {
    color: #ff696b;
  }
  &:last-child {
    margin-right: 0;
  }
  a {
    color: ${(props) => (props.actived ? "#FF696B" : props.dark ? "#e2dada" : "#e2dada")};
    &:hover {
      color: #ff696b;
    }
  }
`;

const Login = styled.button`
  padding: 0.8rem 2rem;
  gap: 1rem;
  width: 10rem;
  height: 4rem;
  background: #ff696b;
  border-radius: 0.4rem;
  border: none;
  color: #ffffff;
  line-height: 1.6rem;
  font-weight: 600;
  cursor: pointer;
`;
const MagniGlass = styled.svg`
  position: absolute;
  right: 2.3rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 2rem;
`;
const SubHeaderButton = styled.button<{ actived: boolean }>`
  color: ${(props) => (props.actived ? "#FFFFFF" : "#666666")};
  strong {
    font-weight: 600;
    position: relative;
    top: -1px;
  }
  padding: 12px;
  width: 120px;
  height: 40px;
  background: ${(props) => (props.actived ? "#FF696B" : "#ececec")};
  border-radius: 4px;
  border: none;
`;
