import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface IStatus {
  imgUrl: string | undefined;
  statusTitle: string | undefined;
  number: string | number;
  arrow: string;
  linkTo: string;
}

const Status = ({ imgUrl, statusTitle, number, arrow, linkTo }: IStatus) => {
  const navigate = useNavigate();

  return (
    <StatusInner
      onClick={() => {
        navigate(linkTo);
      }}>
      <StatusImg src={imgUrl} alt={statusTitle} />
      <SIWrapper>
        <span>{statusTitle}</span>
        <img src={arrow} alt="arrow" />
      </SIWrapper>
      <p>{number}</p>
    </StatusInner>
  );
};

export default Status;

const StatusInner = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  border-right: 1px solid #ececec;
  &:last-child {
    border-right: none;
  }
  p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 32px;
    color: #444444;
  }
`;
const StatusImg = styled.img`
  width: 32px;
  height: 32px;
  margin-bottom: 10px;
`;
const SIWrapper = styled.div`
  display: flex;
  height: 16px;
  align-items: center;
  margin-bottom: 10px;
  text-decoration: none;
  span {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #888888;
  }
  img {
    width: 6px;
    height: 10px;
    margin-left: 7px;
  }
`;
