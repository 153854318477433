import axios from "axios";
import { useEffect, useState } from "react";
interface IData {
  coin: number;
  followings: any;
  followingTotal: number;
  giftsend: any;
  giftsendTotal: number;
  giftrecv: any;
  giftTotal: number;
  wishlist: any;
  wishlistTotal: number;
}
export default function useFetchiungMypageData() {
  const [data, setData] = useState<IData>({
    coin: 0,
    followings: [],
    followingTotal: 0,
    giftsend: [],
    giftsendTotal: 0,
    giftrecv: [],
    giftTotal: 0,
    wishlist: [],
    wishlistTotal: 0,
  });
  useEffect(() => {
    axios
      .all([
        //1. 내 코인 내역
        axios.get(`${process.env.REACT_APP_BASE_URL}/my/coin/list`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TEST_TOKEN}`,
          },
        }),
        //2. 팔로우중인 셀럽
        axios.get(`${process.env.REACT_APP_BASE_URL}/user/following/list`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TEST_TOKEN}`,
          },
        }),
        //3. 보낸 선물
        axios.get(`${process.env.REACT_APP_BASE_URL}/gift/send/list`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TEST_TOKEN}`,
          },
        }),
        //4. 받은 선물
        axios.get(`${process.env.REACT_APP_BASE_URL}/gift/recv/list`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TEST_TOKEN}`,
          },
        }),
        //5. 위시리스트
        axios.get(`${process.env.REACT_APP_BASE_URL}/item/heart/list`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TEST_TOKEN}`,
          },
        }),
      ])
      .then(
        axios.spread((res1, res2, res3, res4, res5) =>
          setData((prev) => {
            return {
              ...prev,
              coin: res1.data.data.coin,
              followings: res2.data.data.followings,
              followingTotal: res2.data.data.total,
              giftsend: res3.data.data.orders,
              giftsendTotal: res3.data.data.total,
              giftrecv: res4.data.data.gifts,
              giftTotal: res3.data.data.total + res4.data.data.total,
              wishlist: res5.data.data.items,
              wishlistTotal: res5.data.data.total,
            };
          }),
        ),
      )
      .catch((err) => console.log(err));
  }, []);
  console.log(data);

  return data;
}
