import styled from "styled-components";
import arrow from "../../images/mypageIcons/arrow.png";
import { useNavigate } from "react-router-dom";

interface IMypageHeader {
  Title: string;
  linkTo: string;
}

export default function MypageHeader({ Title, linkTo }: IMypageHeader) {
  const navigate = useNavigate();
  return (
    <TitleWrapper>
      <InnerHeader>{Title}</InnerHeader>
      <More
        onClick={() => {
          navigate(linkTo);
        }}>
        더 보기
        <img src={arrow} alt="arrow" />
      </More>
    </TitleWrapper>
  );
}
/* Box Title */
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const InnerHeader = styled.h3`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #444444;
`;
const More = styled.a`
  cursor: pointer;
  height: 20px;
  display: flex;
  justify-content: center;
  font-family: "Pretendard";
  font-style: normal;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #888888;
  text-decoration: none;
  img {
    width: 6px;
    height: 10px;
    margin-left: 7px;
  }
`;
