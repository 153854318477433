import styled from "styled-components";
import { ICategory } from "./header.components";
import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

function MypageSidebar() {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({
    name: "",
    username: "",
    email: "",
    country: "",
    countryUid: "",
    thumbnail: "",
  });

  const [fan, setFan] = useState(""); //type: 1 팬, type: 2 셀럽, type: 3 그룹

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/my/profile`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TEST_TOKEN}`,
        },
      })
      .then((res) => {
        const data = res.data.data.profile;
        const profileData = {
          name: data.name,
          username: data.username,
          email: data.account.email,
          country: data.countries.name,
          countryUid: data.countries.uid,
          thumbnail: data.account.user.thumbnail,
        };
        setProfile(profileData);

        if (data.type === 1) {
          setFan("팬");
        } else if (data.type === 2) {
          setFan("셀럽");
        } else if (data.type === 3) {
          setFan("그룹");
        }
      });
  }, []);

  return (
    <Wrapper>
      <Profile>
        <UserInfo>
          <Photo>
            <img src={profile.thumbnail} alt="profilethumbnail" />
          </Photo>
          <Name>{profile.name}</Name>
          <Status>
            <span>{fan}</span> ∙ <span>{profile.country}</span>
          </Status>
          <Email>{profile.email}</Email>
        </UserInfo>
        <ModifyBtn>
          <Link to="/mypage/profile">회원정보 변경</Link>
        </ModifyBtn>
      </Profile>
      <Menu>
        <MenuInner>
          <Title>활동</Title>
          {submenusActivity.map((el, idx) => (
            <Submenu
              onClick={() => {
                el.to && navigate(el.to);
              }}
              key={idx}>
              {el.value}
            </Submenu>
          ))}
        </MenuInner>
        <MenuInner>
          <Title>설정</Title>
          {submenusSetting.map((el, idx) => (
            <Submenu
              onClick={() => {
                el.to && navigate(el.to);
              }}
              key={idx}>
              {el.value}
            </Submenu>
          ))}
        </MenuInner>
        <MenuInner>
          <Title>고객지원</Title>
          {submenusSupport.map((el, idx) => (
            <Submenu
              onClick={() => {
                el.to && navigate(el.to);
              }}
              key={idx}>
              {el.value}
            </Submenu>
          ))}
        </MenuInner>
      </Menu>
    </Wrapper>
  );
}
export default MypageSidebar;

const submenusActivity: ICategory[] = [
  { value: "팔로우중인 셀럽", to: "/" },
  { value: "내 토리 현황", to: "" },
  { value: "위시 리스트", to: "" },
  { value: "보낸 선물", to: "" },
  { value: "받은 선물", to: "" },
  { value: "응원보드 참여이력", to: "" },
  { value: "주고받은 메세지", to: "" },
];
const submenusSetting: ICategory[] = [
  { value: "회원정보변경", to: "" },
  { value: "배송지 설정", to: "" },
  { value: "알림 설정", to: "" },
  { value: "비밀번호 변경", to: "" },
  { value: "회원 탈퇴", to: "" },
  { value: "친구 추천 코드", to: "" },
];
const submenusSupport: ICategory[] = [
  { value: "공지사항", to: "" },
  { value: "자주 묻는 질문", to: "" },
  { value: "1:1 문의하기", to: "" },
];
const Wrapper = styled.aside`
  width: 240px;
  display: flex;
  flex-direction: column;
  font-family: "Pretendard";
  font-style: normal;
  gap: 40px;
`;
/* Profile Section */
const Profile = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  gap: 20px;
  height: 308px;
  border: 1px solid #ececec;
  border-radius: 10px;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Photo = styled.div`
  width: 80px;
  height: 81px;
  margin-bottom: 9px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 90%;
  }
`;
const Name = styled.div`
  height: 36px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #333333;
`;
const Status = styled.div`
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 4px;
  height: 20px;
  color: #888888;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #888888;
  }
`;
const Email = styled.span`
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #aaaaaa;
`;
const ModifyBtn = styled.button`
  border: 0;
  outline: 0;
  padding: 7px 10px;
  width: 86px;
  height: 32px;
  background: #f5f5f5;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #888888;
  a {
    text-decoration: none;
    color: #888888;
  }
`;
/* Menu Section */
const Menu = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
const MenuInner = styled.div``;
const Title = styled.p`
  padding-bottom: 16px;
  margin-bottom: 16px;
  width: 240px;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  border-bottom: 1px solid #ececec;
  color: #444444;
`;
const Submenu = styled.div`
  padding-left: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #888888;
  cursor: pointer;
`;
