import textBalloon1 from "../../images/aboutFanddle/textBalloon1.png";
import textBalloon2 from "../../images/aboutFanddle/textBalloon2.png";
import textBalloon3 from "../../images/aboutFanddle/textBalloon3.png";
import textBalloon4 from "../../images/aboutFanddle/textBalloon4.png";
import styled, { css, keyframes } from "styled-components";
import { idxProps, Message1Fade } from "./section1.homeComponents";
import React from "react";
interface TextBalloonProps {
  animateStart: boolean;
  delay: number;
  isRight?: boolean;
}
function Section2({ idx }: idxProps) {
  const animateStart = idx === 1;
  return (
    <Wrapper>
      <LeftWrapper>
        <BoldText animateStart={animateStart} style={{ fontWeight: "100" }}>
          좋아하는 셀럽에게 <br /> <strong>직접 내 마음을 전하기 힘드셨죠 ?</strong>
        </BoldText>
        <NormalText animateStart={animateStart}>이젠 fanddle이 책임지고 당신의 마음을 전해줄게요</NormalText>
      </LeftWrapper>
      <RightWrapper>
        <TextBalloon src={textBalloon1} animateStart={animateStart} delay={1} />
        <TextBalloon src={textBalloon2} animateStart={animateStart} isRight={true} delay={1.3} />
        <TextBalloon src={textBalloon3} animateStart={animateStart} delay={1.6} />
        <TextBalloon src={textBalloon4} animateStart={animateStart} isRight={true} delay={1.9} />
      </RightWrapper>
    </Wrapper>
  );
}

const balloonsFade = keyframes`
  0% {
    opacity : 0;
  }
  100% {
    opacity : 1;
  }
`;
export const NormalTextFade = keyframes`
  0% {
    color : transparent;
  }
  100% {
    color : rgba(170, 170, 170, 1);
  }
`;
export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: #121212;
  display: flex;
  justify-content: center;
`;
export const LeftWrapper = styled.div`
  width: 36vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const RightWrapper = styled.div`
  width: 36vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const BoldText = styled.p<{ animateStart: boolean }>`
  font-size: 2.5vw;
  font-weight: 300;
  color: white;
  margin-bottom: 30px;
  line-height: 150%;
  strong {
    font-weight: 600;
  }
  ${(props) =>
    props.animateStart &&
    css`
      animation: ${Message1Fade} 1.5s ease-in-out;
    `}
`;
export const NormalText = styled.p<{ animateStart: boolean }>`
  font-size: 1.25vw;
  color: transparent;
  line-height: 160%;
  ${(props) =>
    props.animateStart &&
    css`
      animation: ${NormalTextFade} 1.5s 0.5s ease-in-out;
    `};
  strong {
    font-weight: 600;
  }
  animation-fill-mode: forwards;
`;
const TextBalloon = styled.img<TextBalloonProps>`
  width: 18.75vw;
  opacity: 0;
  ${({ isRight }) =>
    isRight &&
    css`
      margin: 0 0 0 auto;
    `}
  ${(props) =>
    props.animateStart &&
    css`
      animation: ${balloonsFade} 0.5s ${props.delay}s ease-in;
    `};
  animation-fill-mode: forwards;
`;

export default React.memo(Section2);
